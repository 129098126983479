import React from "react";
import { useLocation } from "react-router-dom";

const MCTAuth = () => {
   const location = useLocation();
    const { img  } = location.state || {};
   
    const prod =  {
      "id": 2,
      "img": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/MCT_Auth.jpg",
      "img2": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/MCT_auth_img.png",
      "head_name": "MCT Authenticator",
      "name": "MCT Authenticator",
      "title": "Secure, Reliable Authentication and Authorization",
      "intro": "Introduction",
      "intro_description": "MCT Authenticator is a robust tool providing secure authentication and authorization services for applications and digital services. Developed to empower developers, MCT Authenticator simplifies the process of integrating security features, eliminating the need to write complex code for identity management. With MCT Authenticator, developers can focus on building exceptional user experiences, knowing that security is seamlessly handled in the background. From secure login to user permissions, MCT Authenticator is designed to meet the highest standards of data protection and user identity management.",
      "ques": "Why MCT Authenticator?",
      "ques_description": "Today, cybersecurity threats are increasing, and organizations require stringent access controls to safeguard data and user identities. MCT Authenticator offers an easy, efficient, and reliable solution for implementing authentication and authorization in your applications. This tool is essential for developers who want to deliver secure applications without having to become security experts. Built for flexibility, MCT Authenticator integrates smoothly with existing applications and can be customized to meet unique security requirements, ensuring that access to sensitive data is always controlled and monitored.",
      "topics": [
        {
          "title": "Core Benefits of MCT Authenticator",
          "benefits": [
            {
              "title": "Simplified Security Integration :",
              "content": "MCT Authenticator removes the need for complex coding, allowing developers to implement secure authentication and authorization features quickly and efficiently."
            },
            {
              "title": "Enhanced User Trust and Experience :",
              "content": "With MCT Authenticator, users gain secure access to applications without hassle, enhancing trust and providing a seamless login experience."
            },
            {
              "title": "Comprehensive Data Protection :",
              "content": "MCT Authenticator ensures that only authorized users can access specific data or functionalities, minimizing the risk of unauthorized access."
            },
            {
              "title": "Comprehensive Data Protection :",
              "content": "MCT Authenticator ensures that only authorized users can access specific data or functionalities, minimizing the risk of unauthorized access."
            },
            {
              "title": "Flexible Deployment Options :",
              "content": "Designed to adapt to various environments, MCT Authenticator supports deployment in cloud, on-premises, or hybrid setups, meeting the needs of diverse IT infrastructures."
            },
            {
              "title": "Cost and Time Efficiency :",
              "content": "By reducing the need for custom security code, MCT Authenticator saves developers time and resources, allowing for faster deployment and lower maintenance costs."
            }
          ]
        },
        {
          "title": "Standout Features of MCT Authenticator",
          "benefits": [
            {
              "title": "Multi-Factor Authentication (MFA) :",
              "content": "MCT Authenticator provides robust multi-factor authentication options, adding an additional layer of security to prevent unauthorized access, especially useful for sensitive or high-risk applications."
            },
            {
              "title": "Role-Based Access Control (RBAC) :",
              "content": "Define user roles and set permissions easily, ensuring that users have access only to the data and features relevant to their responsibilities, improving both security and usability."
            },
            {
              "title": "Single Sign-On (SSO) :",
              "content": "Streamline the user experience with single sign-on capabilities. Users can access multiple applications with one secure login, making MCT Authenticator ideal for organizations with multiple internal or external applications."
            },
            {
              "title": "Token-Based Authentication :",
              "content": "MCT Authenticator supports token-based authentication,a secure method that allows users to authenticate with tokens, ideal for mobile and web applications requiring fast, secure access."
            },
            {
              "title": "Easy API Integrations :",
              "content": "MCT Authenticator comes with an intuitive API that enables easy integration with various platforms and applications, ensuring compatibility and a quick setup process for developers."
            },
            {
              "title": "Comprehensive Logging and Reporting :",
              "content": "Track user access, view login patterns, and analyze security metrics with MCT Authenticator’s comprehensive logging and reporting tools, enabling compliance and actionable insights for improved security posture."
            },
            {
              "title": "Adaptive Authentication :",
              "content": " Leverage adaptive authentication to assess login risk dynamically based on factors like location, device, and behavior, enabling a smarter, more secure approach to user authentication."
            }
          ]
        }
      ]
    }
  return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-full max-[900px]:w-full flex flex-col  gap-5 text-white text-md p-8 text-md">
        <div className="w-full flex items-center justify-center">
          <img
            src={img}
            alt="img"
            loading="lazy"
            className="w-4/5 max-[400px]:w-full h-[380px] rounded-xl"
          />
        </div>
        <div className="w-full flex text-lg items-center  gap-2">
          {prod.head_name} : {prod.title}
        </div>
        <h1 className="text-white text-2xl font-medium">Introduction</h1>
        <p>{prod.intro_description}</p>
        <h1 className="text-white text-2xl font-medium">{prod.ques}</h1>
        <p>{prod.ques_description}</p>
        {prod.topics.map((data) => (
          <div key={data.id} className="w-full flex flex-col gap-4">
            <h1 className="text-white text-2xl font-medium">{data.title}</h1>

            {data.benefits.map((point, index) => (
              <li key={index}>
                <span className="font-medium text-white"> {point.title}</span>{" "}
                {point.content}
              </li>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default MCTAuth; 
