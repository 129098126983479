import React from "react";
import cloud from "../../images/service/cloud.jpg";
import data_center from "../../images/service/data-center.jpg";
import enpnet from "../../images/service/enterprise-network.jpg";
import nfo from "../../images/service/network-field-operation.jpg";
import mangservice from "../../images/service/managedservices.jpg";
import ict from "../../images/service/ICT.jpg";
import mtech from "../../images/service/mtech.jpg";
import nrc from "../../images/service/nrc.jpg";
import banner from "../../images/products/product.png";
import img6 from "../../images/products/blog-two-6.jpg";
import { useNavigate } from "react-router-dom";

function Service() {
  const navigate = useNavigate();
  return (
    <div className="product-page w-full ">
      <div className="w-full h-screen max-[500px]:h-[50vh] bg-black bg-opacity-25">
        <img
          src={banner}
          alt="img"
          loading="lazy"
          className="w-full h-[100%]  "
        />
        <div className=" w-full absolute flex flex-col my-5 gap-4 px-10 bottom-[10%] max-[500px]:bottom-[55%]">
          <span className="text-white text-6xl font-bold max-[500px]:text-3xl ">
            Our Services
          </span>
          <span className="w-40 h-2 rounded-full bg-white max-[500px]:h-1"></span>
        </div>
      </div>
      <div className="w-full grid grid-cols-3 max-[650px]:grid-cols-1 max-[1200px]:grid-cols-2 py-32 px-10 gap-6">
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/cloud")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">Cloud Infrastructure</span>
            </p>
            <img src={cloud} loading="lazy" alt="img" className="rounded-3xl" />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/data_center")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">Data Center</span>
            </p>
            <img
              src={data_center}
              loading="lazy"
              alt=""
              className="rounded-3xl"
            />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/network")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">Enterprise Network</span>
            </p>
            <img src={enpnet} alt="" loading="lazy" className="rounded-3xl" />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/operation")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">
                Network Field Operation
              </span>
            </p>
            <img src={nfo} alt="" loading="lazy" className="rounded-3xl" />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/manageservice")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">Managed Service</span>
            </p>
            <img
              src={mangservice}
              loading="lazy"
              alt=""
              className="rounded-3xl"
            />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/ict")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">ICT Security</span>
            </p>
            <img src={ict} alt="" loading="lazy" className="rounded-3xl" />
          </div>
        </div>

        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/mtech")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">
                Multi-vendor Technical Support
              </span>
            </p>
            <img src={mtech} alt="" loading="lazy" className="rounded-3xl" />
          </div>
        </div>
        <div className="w-full py-12 px-5 border-t-4 border-cyan-400 ">
          <div
            onClick={() => navigate("/nrc")}
            className="w-full py-5 border-t border-white hover:scale-110 cursor-pointer"
          >
            <p className="text-white mb-4">
              <span className="text-2xl font-medium">NRC</span>
            </p>
            <img src={nrc} alt="" loading="lazy" className="rounded-3xl" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
