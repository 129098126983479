import React from "react";
import { useLocation } from "react-router-dom";

const NAC = () => {
   const location = useLocation();
    const { img  } = location.state || {};
   
    const prod =   {
      "id": 8,
      "img": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/nac.jpg",
      "img2": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/nac.jpg",
      "head_name": "MCT Access Watch",
      "name": "MCT Access Watch (NAC)",
      "title": "Secure, Control, and Monitor Network Access",
      "intro": "Introduction",
      "intro_description": "MCT Access Watch (NAC) is a comprehensive Network Access Control solution that delivers robust pre- and post-connect security for wired, wireless, and VPN network environments. Designed to provide visibility and control over network access, Access Watch helps organizations secure their networks by authenticating, authorizing, and managing devices and users before they connect. With Access Watch, businesses gain the confidence to enforce strict access policies, secure sensitive data, and keep unauthorized devices off the network.",
      "ques": "Why Access Watch (NAC)?",
      "ques_description": "In today’s dynamic network environments, traditional perimeter security is no longer sufficient to protect sensitive data and critical assets. MCT Access Watch (NAC) addresses these challenges by allowing organizations to enforce identity-based access controls that prevent unauthorized access to network resources. By providing pre- and post-connect controls, Access Watch helps businesses safeguard against internal and external threats, meet regulatory compliance, and ensure that only authenticated users and devices can access the network. It’s the ideal solution for organizations looking to gain complete visibility and control over network access.",
      "topics": [
        {
          "title": "Core Benefits of Access Watch (NAC)",
          "benefits": [
            {
              "title": "Enhanced Network Security :",
              "content": "Access Watch prevents unauthorized devices and users from accessing the network, significantly reducing the risk of data breaches and cyber threats."
            },
            {
              "title": "Increased Visibility :",
              "content": " Gain complete visibility into all devices connected to the network, allowing administrators to monitor network activity, detect potential threats, and respond quickly to unauthorized access."
            },
            {
              "title": "Regulatory Compliance :",
              "content": " Access Watch helps organizations meet industry compliance standards by enforcing strict access controls and generating detailed audit logs for network access."
            },
            {
              "title": "Streamlined Access Management :",
              "content": " With centralized policy management, Access Watch simplifies the process of enforcing and managing access controls across wired, wireless, and VPN networks."
            },
            {
              "title": "Reduced Risk of Insider Threats :",
              "content": "By controlling who and what connects to the network, Access Watch mitigates the risks associated with internal threats, ensuring a secure environment for both employees and guests."
            }
          ]
        },
        {
          "title": "Standout Features of Access Watch (NAC)",
          "benefits": [
            {
              "title": "Pre-Connect and Post-Connect Control :",
              "content": "Enforce policies before devices and users connect to the network and continuously monitor them afterward to ensure ongoing compliance and security."
            },
            {
              "title": "Device Authentication and Authorization :",
              "content": "Identify, authenticate, and authorize all devices attempting to connect, preventing unauthorized devices from gaining access to critical resources."
            },
            {
              "title": "Granular Policy Enforcement :",
              "content": "Create and apply granular access policies based on user roles, device types, locations, and network conditions, allowing flexibility while maintaining security standards."
            },
            {
              "title": "Role-Based Access Control (RBAC) :",
              "content": "Customize access based on user roles, providing appropriate network privileges to different categories of users, such as employees, contractors, and guests."
            },
            {
              "title": "Guest and BYOD Management :",
              "content": "Manage guest access and Bring Your Own Device (BYOD) policies with ease, allowing secure access for visitors and personal devices without compromising security."
            },
            {
              "title": "Real-Time Monitoring and Alerts : ",
              "content": "Receive instant notifications when unauthorized access is detected, helping administrators respond quickly to potential security incidents."
            },
            {
              "title": "Comprehensive Reporting and Audit Trails :",
              "content": "Access Watch generates detailed reports on network access and usage, allowing organizations to maintain thorough records for compliance and security audits."
            },
            {
              "title": "Seamless Integration with Existing Infrastructure :",
              "content": "MCT Access Watch is compatible with a variety of network devices and platforms, ensuring smooth integration with your existing infrastructure without disruptions."
            }
          ]
        }
      ]
    }
  return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-full max-[900px]:w-full flex flex-col  gap-5 text-white text-md p-8 text-md">
        <div className="w-full flex items-center justify-center">
          <img
            src={img}
            alt="img"
            loading="lazy"
            className="w-4/5 max-[400px]:w-full h-[380px] rounded-xl"
          />
        </div>
        <div className="w-full flex text-lg items-center  gap-2">
          {prod.head_name} : {prod.title}
        </div>
        <h1 className="text-white text-2xl font-medium">Introduction</h1>
        <p>{prod.intro_description}</p>
        <h1 className="text-white text-2xl font-medium">{prod.ques}</h1>
        <p>{prod.ques_description}</p>
        {prod.topics.map((data) => (
          <div key={data.id} className="w-full flex flex-col gap-4">
            <h1 className="text-white text-2xl font-medium">{data.title}</h1>

            {data.benefits.map((point, index) => (
              <li key={index}>
                <span className="font-medium text-white"> {point.title}</span>{" "}
                {point.content}
              </li>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default NAC; 
