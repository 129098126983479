import React, { useEffect } from "react";
import img from "../../images/banner-img.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { TypeAnimation } from "react-type-animation";
import { PiShieldWarningLight } from "react-icons/pi";
import { PiGlobeLight } from "react-icons/pi";
import { MdOutlinePhonelinkLock } from "react-icons/md";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { HiOutlineGlobeAsiaAustralia } from "react-icons/hi2";
import eventsheild from "../../images/products/blog-two-2.jpg";
import nms from "../../images/products/mct-nms.jpg";
import siem from "../../images/products/SIEM.png";
import email_gateway from "../../images/products/email-gatway.jpg";
import doc from "../../images/products/maple-doc.avif";

import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="banner w-full flex flex-col pb-10 items-center justify-center  ">
      <div className="w-full pt-20 px-20  max-[650px]:px-6 flex  justify-between  items-center">
        <div className="content text-5xl max-[950px]:text-4xl max-[780px]:text-3xl max-[650px]:text-2xl font-bold text-white max-[500px]:pt-10 pb-5">
          <span className="max-[500px]:text-5xl">
            Welcome to Maple <br />
            Cloud Technologies
          </span>
          <br />
          <TypeAnimation
            sequence={[
              "Your Trusted Security Partner",
              1000,
              "Your Trusted Security Partner",
              1000,
              "Your Trusted Security Partner",
              1000,
              "Your Trusted Security Partner",
              1000,
            ]}
            wrapper="span"
            speed={50}
            style={{ fontSize: "20px", display: "inline-block" }}
            repeat={Infinity}
          />
          <br />
          <div className="text-center bg-cyan-700 text-white w-32 mt-10 text-sm py-3 rounded-md max-[800px]:text-xs max-[800px]:py-2 max-[800px]:w-40">
            <button onClick={() => navigate("/contactpage")}>
              Book a Demo
            </button>
          </div>
        </div>
        <img
          src={img}
          className=" w-[420px] max-[950px]:w-[300px] max-[780px]:w-[200px] max-[650px]:w-[200px] max-[500px]:hidden"
          alt=""
        />
      </div>
      <div className="max-[1020px]:grid-cols-3 max-[500px]:grid-cols-2 grid grid-cols-5 gap-6 w-full px-14 max-[650px]:px-6 max-[780px]:mt-5">
        <div
          onClick={() =>
            navigate(`/eventshield`, {
              state: {
                img: eventsheild,
              },
            })
          }
          data-aos="zoom-in"
          className="w-full border cursor-pointer border-white rounded-xl p-4  flex flex-col gap-3 items-center justify-center max-[500px]:p-2"
        >
          <PiShieldWarningLight className="text-5xl text-red-600 max-[500px]:text-4xl" />
          <p className="text-lg text-white font-medium text-center max-[500px]:text-sm ">
            EventShield (SIEM + XDR + SOAR)
          </p>
        </div>
        <div
          onClick={() =>
            navigate(`/maple_nms`, {
              state: {
                img: nms,
              },
            })
          }
          data-aos="zoom-in"
          className="w-full border cursor-pointer border-white rounded-xl p-4  flex flex-col gap-3 items-center justify-center max-[500px]:p-2"
        >
          <PiGlobeLight className="text-5xl text-red-600 max-[500px]:text-4xl" />
          <p className="text-lg text-white font-medium text-center max-[500px]:text-sm  ">
            Network Monitoring System (MCTNMS)
          </p>
        </div>
        <div
          onClick={() =>
            navigate(`/mct_auth`, {
              state: {
                img: siem,
              },
            })
          }
          data-aos="zoom-in"
          className="w-full border cursor-pointer border-white rounded-xl p-4 flex flex-col gap-3 items-center justify-center  max-[500px]:p-2"
        >
          <MdOutlinePhonelinkLock className="text-5xl text-red-600 max-[500px]:text-4xl" />
          <p className="text-lg text-white font-medium text-center max-[500px]:text-sm   ">
            Identity & Access Management (MCT Authenticator)
          </p>
        </div>
        <div
          onClick={() =>
            navigate(`/MailBridge`, {
              state: {
                img: email_gateway,
              },
            })
          }
          data-aos="zoom-in"
          className="w-full border cursor-pointer border-white rounded-xl p-4 flex flex-col gap-3 items-center justify-center max-[500px]:p-2"
        >
          <MdOutlineMarkEmailRead className="text-5xl text-red-600 max-[500px]:text-4xl" />
          <p className="text-lg text-white font-medium text-center max-[500px]:text-sm  ">
            MailBridges
          </p>
        </div>
        <div
          onClick={() =>
            navigate(`/maple_doc`, {
              state: {
                img: doc,
              },
            })
          }
          data-aos="zoom-in"
          className="w-full cursor-pointer border border-white rounded-xl p-3 flex flex-col gap-3 items-center justify-center max-[500px]:p-3"
        >
          <HiOutlineGlobeAsiaAustralia className="text-5xl text-red-600 max-[500px]:text-4xl" />
          <p className="text-lg text-white font-medium text-center max-[500px]:text-sm  ">
            MapleDoc (DMS)
          </p>
        </div>
      </div>
    </div>
  );
}

export default Banner;
