import React, { useState } from "react";
import "./ChatInput.css";

const ChatInput = ({ question, onSend }) => {
  const [input, setInput] = useState("");

  const handleSend = () => {
    if (input.trim() === "") return;
    onSend(input);
    setInput("");
  };

  return (
    <div className="chat-input">
      {question.type === "text" && (
        <div className="input-row">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your answer..."
          />
          <button onClick={handleSend}>Send</button>
        </div>
      )}
      {question.type === "options" && (
        <div className="options">
          {question.options.map((option, index) => (
            <button key={index} onClick={() => onSend(option)}>
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChatInput;
