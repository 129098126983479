import React from "react";
import banner from "../../images/products/products.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import NetworkSec from "./NetworkSec";
import AllProd from "./AllProd";
import BussDrivenSoft from "./BussDrivenSoft";
import CyberSec from "./CyberSec";

function ProductPage() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("network_sec");

  const switchComp = () => {
    switch (activeTab) {
      case "network_sec":
        return <NetworkSec />;
      case "business_driven_software":
        return <BussDrivenSoft />;
      case "cyber_sec":
        return <CyberSec />;
      case "all_products":
        return <AllProd />;
      default:
        return <AllProd />;
    }
  };

  return (
    <div className="product-page w-full">
      <div className="w-full h-screen max-[500px]:h-[50vh] bg-black bg-opacity-25">
        <img src={banner} alt="img" className="w-full h-[100%]" />
        <div className="w-full absolute flex flex-col my-5 gap-4 px-10 bottom-[10%] max-[500px]:bottom-[55%]">
          <span className="text-white text-6xl font-bold max-[500px]:text-3xl">
            Our Products
          </span>
          <span className="w-40 h-2 rounded-full bg-white max-[500px]:h-1"></span>
        </div>
      </div>
      <div className="flex justify-center w-full items-center">
        <div className="flex justify-between mt-7 w-4/5 transition-none mx-6 border-b border-gray-300 flex-wrap md:flex-nowrap">
          <button
            className={`px-4 py-2 text-lg font-medium ${
              activeTab === "network_sec"
                ? "text-white border-b-4  border-blue-400"
                : "text-gray-500 hover:text-white"
            }`}
            onClick={() => setActiveTab("network_sec")}
          >
            Network Security
          </button>
          <button
            className={`px-4 py-2 text-lg font-medium ${
              activeTab === "cyber_sec"
                ? "text-white border-b-4 border-blue-400"
                : "text-gray-500 hover:text-white"
            }`}
            onClick={() => setActiveTab("cyber_sec")}
          >
            Cyber Security
          </button>
          <button
            className={`px-4 py-2 text-lg font-medium ${
              activeTab === "business_driven_software"
                ? "text-white border-b-4 border-blue-400"
                : "text-gray-500 hover:text-white"
            }`}
            onClick={() => setActiveTab("business_driven_software")}
          >
            Business Application
          </button>
          <button
            className={`px-4 py-2 text-lg font-medium ${
              activeTab === "all_products"
                ? "text-white border-b-4 border-blue-400"
                : "text-gray-500 hover:text-white"
            }`}
            onClick={() => setActiveTab("all_products")}
          >
            All Products
          </button>
        </div>
      </div>
      <div className="w-full px-4 py-6">{switchComp()}</div>
    </div>
  );
}

export default ProductPage;
