import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import data from "../../pages/data/Product.json";
import { useLocation } from "react-router-dom";

const NMS = () => {
   const location = useLocation();
    const { img  } = location.state || {};
   
    const prod = {
      "id": 4,
      "img": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/nms.jpg",
      "img2": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/mct-nms.jpg",
      "head_name": "MCT NMS",
      "name": "MCT NMS",
      "title": " Advanced Network Management for Seamless Connectivity",
      "intro": "Introduction",
      "intro_description": "MCT NMS is a sophisticated network management platform developed to monitor and manage complex network infrastructures with ease and precision. As networks grow in size and complexity, MCT NMS provides a reliable, comprehensive solution for end-to-end monitoring and management of network devices, servers, applications, and services. With MCT NMS, businesses can streamline their network operations, quickly detect and resolve issues, and optimize network performance to ensure uninterrupted connectivity and productivity.",
      "ques": "Why MCT NMS?",
      "ques_description": "Efficient network management is essential for organizations relying on stable, secure, and high-performing network environments. MCT NMS is built to address the challenges of managingcomplex networks, offering centralized control, real-time monitoring, and intelligent insights. Trusted by organizations across industries, MCT NMS empowers IT teams with tools to reduce downtime, enhance security, and achieve proactive maintenance, ensuring your network is always optimized for performance and resilience.",
      "topics": [
        {
          "title": "Core Benefits of MCT NMS",
          "benefits": [
            {
              "title": "Comprehensive Network Visibility :",
              "content": "MCT NMS provides full visibility into every part of your network infrastructure, ensuring that no device, server, or service goes unnoticed. This complete view enables teams to manage and troubleshoot effectively."
            },
            {
              "title": "Proactive Issue Detection :",
              "content": "With advanced monitoring and alerting capabilities, MCT NMS detects potential issues before they impact your network, allowing IT teams to take preventive measures and reduce downtime."
            },
            {
              "title": "Optimized Performance Management :",
              "content": "MCT NMS offers tools for monitoring and improving network performance, ensuring optimal bandwidth usage, minimizing latency, and maximizing productivity."
            },
            {
              "title": "Streamlined Network Operations :",
              "content": "By centralizing network management, MCT NMS reduces the complexity of network administration, enabling efficient configuration and management from a single, intuitive interface."
            },
            {
              "title": "Improved Security and Compliance :",
              "content": "With MCT NMS’s security monitoring features, organizations can keep their networks secure by identifying vulnerabilities, tracking unauthorized access, and staying compliant with industry standards."
            }
          ]
        },
        {
          "title": "Standout Features of MCT NMS",
          "benefits": [
            {
              "title": "End-to-End Monitoring :",
              "content": "MCT NMS covers the entire network ecosystem, including routers, switches, firewalls, servers, applications, and services. This ensures that every component of your network is monitored in real time."
            },
            {
              "title": "Real-Time Alerts and Notifications :",
              "content": "MCT NMS generates instant alerts for anomalies, helping IT teams respond quickly to issues before they escalate. Customizable notifications allow administrators to receive critical information through multiple channels."
            },
            {
              "title": "Intelligent Data Analysis and Reporting:",
              "content": "Maple Doc’s optimized query functions provide fast and reliable data retrieval, ensuring users have quick access to the information they need, when they need itGenerate insightful reports on network performance, traffic trends, device health, and more. MCT NMS’s analytics tools enable data-driven decisions to enhance network reliability and performance."
            },
            {
              "title": "Automated Configuration Management :",
              "content": "Save time and reduce errors with MCT NMS’s automated configuration tools, allowing for streamlined configuration changes and updates across multiple devices."
            },
            {
              "title": "Scalability :",
              "content": "MCT NMS is designed to scale as your network grows, making it ideal for businesses of any size. Easily add new devices and services to the platform without disrupting network management."
            },
            {
              "title": "Role-Based Access Control (RBAC): : ",
              "content": "Enhance security by assigning role-specific permissions, ensuring that only authorized personnel have access to certain network management functions and data."
            },
            {
              "title": "Customizable Dashboards :",
              "content": "MCT NMS offers flexible dashboards that can be tailored to the specific needs of each team member, providing quick access to the most relevant data and metrics."
            },
            {
              "title": "Historical Data Tracking :",
              "content": "Track network performance over time with historical data logs, allowing teams to identify patterns, forecast needs, and improve resource planning."
            }
          ]
        }
      ]
    }
  return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-full max-[900px]:w-full flex flex-col  gap-5 text-white text-md p-8 text-md">
        <div className="w-full flex items-center justify-center">
          <img
            src={img}
            alt="img"
            loading="lazy"
            className="w-4/5 max-[400px]:w-full h-[380px] rounded-xl"
          />
        </div>
        <div className="w-full flex text-lg items-center  gap-2">
          {prod.head_name} : {prod.title}
        </div>
        <h1 className="text-white text-2xl font-medium">Introduction</h1>
        <p>{prod.intro_description}</p>
        <h1 className="text-white text-2xl font-medium">{prod.ques}</h1>
        <p>{prod.ques_description}</p>
        {prod.topics.map((data) => (
          <div key={data.id} className="w-full flex flex-col gap-4">
            <h1 className="text-white text-2xl font-medium">{data.title}</h1>

            {data.benefits.map((point, index) => (
              <li key={index}>
                <span className="font-medium text-white"> {point.title}</span>{" "}
                {point.content}
              </li>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default NMS; 
