import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollBtn from "./components/ScrollBtn";
import { DataProvider } from "../src/blogContext/Context";
import prodData from "../src/pages/data/Product.json";
import ChatbotContainer from "./components/chatbot/ChatbotContainer";

function Layout({ children }) {
  const [btn, setBtn] = useState(false);
  const [prod_data, setProd_data] = useState(prodData);
  const [prod, setProd] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setBtn(true);
      } else {
        setBtn(false);
      }
    });
  });

  const Each_proddetail = (id) => {
    setProd_data((prev) =>
      prev.filter((each) => (each.id == id ? setProd(each) : prod_data))
    );
    navigate("/mct_auth");
  };

  return (
    <DataProvider value={{ prod_data, Each_proddetail, prod }}>
      <Navbar />
      {children}
      {btn ? <ScrollBtn /> : ""}
      <Footer />
      <ChatbotContainer />
    </DataProvider>
  );
}

export default Layout;
