// import React, { useEffect } from "react";
// import instreact from "../../images/aboutuspage/vectors/Intersect.png";
// import instreact1 from "../../images/aboutuspage/vectors/Intersect1.png";
// import inst_vec from "../../images/aboutuspage/vectors/Vector 6.png";
// import vec1 from "../../images/aboutuspage/vectors/Vector 4.png";
// import vec2 from "../../images/aboutuspage/vectors/Vector 16.png";
// import img_vec from "../../images/aboutuspage/vectors/Organization.png";
// import img2_vec from "../../images/aboutuspage/vectors/Organization (1).png";
// import img3_vec from "../../images/aboutuspage/vectors/Organization (2).png";
// import img4_vec from "../../images/aboutuspage/vectors/Organization (3).png";
// import img5_vec from "../../images/aboutuspage/vectors/Organization (4).png";
// import star from "../../images/aboutuspage/vectors/star.png";
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import { useNavigate } from "react-router-dom";
// function Jouney() {
//   const navigate = useNavigate();
//   useEffect(() => {
//     AOS.init({duration : 1000});
//   },[]);
//   return (
//     <div className="w-full h-full home-about py-20 max-[650px]:px-6 px-32 max-[950px]:px-6">
//       <div className="bg-cyan-500 w-1 h-[1900px] max-[500px]:h-[2900px] absolute rounded-full"></div>
//       <div className="w-full  flex max-[650px]:flex-col gap-20 relative mt-24">
//         <img src={inst_vec} className="w-10 absolute -top-32 -left-4 " />
//         <div className="w-full flex flex-col gap-4 pl-10  -mt-20">
//           <p className="text-cyan-300 text-lg font-medium">OUR STORY</p>
//           <p className="text-white text-lg ">
//             Our journey began when a group of passionate technologists saw the
//             untapped potential of technology to revolutionize industries. With a
//             shared vision, we embarked on a mission to bridge the gap between
//             cutting-edge technology and businesses seeking to harness its power.
//             Over the years, we have evolved into a team of experts who thrive on
//             challenges, creativity, and collaboration.
//           </p>
//           <div className="relative ">
//             <p className="text-cyan-300 text-2xl font-medium mt-10">
//               Since then...
//             </p>
//           </div>
//           <p className="text-white text-lg mt-4 ">
//             We've completed more than 1,200 projects for over 500 distinct
//             <br />
//             enterprises.
//           </p>
//         </div>
//         <div className="w-full -mt-10">
//           <img
//             src={instreact}
//             alt="img"
//             className="w-[350px] max-[500px]:ml-10 max-[500px]:w-[250px] max-[500px]:h-[250px] h-[300px] max-[650px]:ml-24 "
//           />
//         </div>
//       </div>
//       <div className="w-full  flex max-[650px]:flex-col gap-20 relative items-center mt-10">
//         <div className="w-full  gap-4 pl-10 ">
//           <p className="text-white text-lg ">
//             Every year our software developers, QA engineers, designers, and
//             project managers work on hundreds of exciting projects...
//           </p>
//         </div>
//         <div className="w-full   flex items-center  justify-center ">
//           <img src={inst_vec} alt="img" className=" -mr-6 mt-8 w-10 h-10" />
//           <img src={img_vec} alt="img" className="   -mr-10   z-10 w-20 h-20" />
//           <img src={img2_vec} alt="img" className="   w-20 h-20" />
//           <img src={img3_vec} alt="img" className="  -ml-10 w-20 h-20" />
//           <img src={img4_vec} alt="img" className="  -ml-10  w-20 h-20" />
//           <img src={img5_vec} alt="img" className="   -ml-10 w-20 h-20 z-10" />
//           <img
//             src={inst_vec}
//             alt="img"
//             className="  -ml-10 -mt-10  w-14 h-14"
//           />
//         </div>
//       </div>
//       <div className="w-full relative flex mt-24">
//         <div className="w-  pl-10 "></div>
//       </div>
//       <div className="w-full  pl-10 mt-14 relative">
//         <div className="w-5 h-5 rounded-full bg-green-600 absolute top-2 -left-2"></div>
//         <p className="text-cyan-300 text-2xl font-medium">How we did it...</p>
//         <img src={star} alt="start-img" className="w-10 my-5" />
//         <div className="max-[650px]:grid-cols-1  grid grid-cols-3 gap-6 w-full  max-[650px]:px-6 max-[780px]:mt-5">
//           <div
//             data-aos="zoom-in"
//             className="bg-neutral-700 bg-opacity-35 flex flex-col gap-3 py-6 max-[650px]:py-3  px-6 max-[950px]:px-6 max-[650px]:px-4 rounded-xl  text-white 5 w-full"
//           >
//             <span className="font-bold text-2xl max-[650px]:text-sm text-indigo-400">
//               2014
//             </span>
//             <h1 className="text-xl   max-[780px]:text-sm max-[650px]:text-[15px] font-medium">Foundation</h1>
//             <p className="text-sm  max-[780px]:text-xs max-[650px]:text-[10px] ">
//               MapleCloud Technologies was founded to address cybersecurity
//               requirements for businesses of all sizes, aiming to provide
//               comprehensive and reliable solutions.
//             </p>
//           </div>
//           <div
//             data-aos="zoom-in"
//             className=" bg-neutral-700 bg-opacity-35 flex flex-col gap-3 py-6 max-[650px]:py-3  px-6 max-[950px]:px-6 max-[650px]:px-4 rounded-xl  text-white 5 w-full"
//           >
//             <span className="font-bold text-2xl max-[650px]:text-sm text-green-400">
//               2018
//             </span>
//             <h1 className="text-xl   max-[780px]:text-sm max-[650px]:text-[15px] font-medium">
//               AgentNet (SDWAN) solutions
//             </h1>

//             <p className="text-sm  max-[780px]:text-xs max-[650px]:text-[10px] ">
//               We introduced AgentNet (SDWAN) solutions to enable organizations
//               to optimize network traffic routing based on real-time conditions,
//               enhancing network performance and reliability.
//             </p>
//           </div>
//           <div
//             data-aos="zoom-in"
//             className=" bg-neutral-700 bg-opacity-35 flex flex-col gap-3 py-6 max-[650px]:py-3  px-6 max-[950px]:px-6 max-[650px]:px-4 rounded-xl  text-white 5 w-full"
//           >
//             <span className="font-bold text-2xl max-[650px]:text-sm text-pink-400">
//               2020
//             </span>
//             <h1 className="text-xl   max-[780px]:text-sm max-[650px]:text-[15px] font-medium">DevRay Launch</h1>
//             <p className="text-sm  max-[780px]:text-xs max-[650px]:text-[10px] ">
//               We launched DevRay, offering advanced security and surveillance
//               solutions designed to meet the evolving needs of modern
//               businesses.
//             </p>
//           </div>
//         </div>
//       </div>
//       <div className="w-full  p-14 max-[650px]:p-4 mt-14 ">
//         <div className="w-full bg-cyan-600 py-10 px-20 max-[650px]:px-6 rounded-2xl">
//           <h1 className="text-white text-3xl font-medium max-[500px]:text-xl">
//             Now, we are committed to making a difference.
//           </h1>
//           <p className="mt-8 text-white text-md max-[500px]:text-sm">
//             Now, more than ever, our commitment to making a difference is
//             unwavering. As we continue to evolve and innovate in the
//             ever-changing landscape of technology, our dedication to driving
//             positive impact remains at the core of everything we do. With a
//             track record of excellence and a team of passionate experts, we are
//             poised to tackle new challenges and create transformative solutions.
//           </p>
//           <div className="w-full   flex  mt-20 justify-center items-center">
//             <img
//               src={vec1}
//               alt="img"
//               className="relative w-28 -top-14 left-20 max-[500px]:w-20"
//             />
//             <img src={instreact1} alt="img" className="w-[550px]" />
//             <img
//               src={vec2}
//               alt="img"
//               className="relative w-28  top-14 right-20 max-[500px]:w-20"
//             />
//           </div>
//           <div className="flex flex-col gap-8 justify-center items-center mt-20 ">
//             <h1 className="text-4xl font-bold text-white text-center max-[500px]:text-xl">
//               Join us in this journey
//             </h1>
//             <button onClick={() => navigate('/contactpage')} className="bg-cyan-700 text-white py-2 px-4 rounded-full">
//               Schedule a Call
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Jouney;

import React, { useEffect } from "react";
import instreact from "../../images/aboutuspage/vectors/Intersect.png";
import inst_vec from "../../images/aboutuspage/vectors/Vector 6.png";
import img_vec from "../../images/aboutuspage/vectors/Organization.png";
import img2_vec from "../../images/aboutuspage/vectors/Organization (1).png";
import img3_vec from "../../images/aboutuspage/vectors/Organization (2).png";
import img4_vec from "../../images/aboutuspage/vectors/Organization (3).png";
import img5_vec from "../../images/aboutuspage/vectors/Organization (4).png";
import star from "../../images/aboutuspage/vectors/star.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../../images/EventImages/1.png";
import img2 from "../../images/EventImages/2.png";
import img3 from "../../images/EventImages/3.png";
import img4 from "../../images/EventImages/4.png";
import img5 from "../../images/EventImages/5.png";
import img6 from "../../images/EventImages/6.png";
import img7 from "../../images/EventImages/7.png";

function Jouney() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite loop
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay interval in milliseconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const carouselItems = [
    { id: 1, image: img1 },
    { id: 2, image: img2 },
    { id: 3, image: img3 },
    { id: 4, image: img4 },
    { id: 5, image: img5 },
    { id: 6, image: img6 },
    { id: 7, image: img7 },
  ];
  return (
    <div className="w-full h-full home-about py-20 max-[650px]:px-6 px-32 max-[950px]:px-6">
      <div className="bg-cyan-500 w-1 h-[1900px] max-[500px]:h-[2900px] absolute rounded-full"></div>
      <div className="w-full  flex max-[650px]:flex-col gap-20 relative mt-24">
        <img src={inst_vec} className="w-10 absolute -top-32 -left-4 " />
        <div className="w-full flex flex-col gap-4 pl-10  -mt-20">
          <p className="text-cyan-300 text-lg font-medium">OUR STORY</p>
          <p className="text-white text-lg ">
            Our journey began when a group of passionate technologists saw the
            untapped potential of technology to revolutionize industries. With a
            shared vision, we embarked on a mission to bridge the gap between
            cutting-edge technology and businesses seeking to harness its power.
            Over the years, we have evolved into a team of experts who thrive on
            challenges, creativity, and collaboration.
          </p>
          <div className="relative ">
            <p className="text-cyan-300 text-2xl font-medium mt-10">
              Since then...
            </p>
          </div>
          <p className="text-white text-lg mt-4 ">
            We've completed more than 1,200 projects for over 500 distinct
            <br />
            enterprises.
          </p>
        </div>
        <div className="w-full -mt-10">
          <img
            src={instreact}
            alt="img"
            className="w-[350px] max-[500px]:ml-10 max-[500px]:w-[250px] max-[500px]:h-[250px] h-[300px] max-[650px]:ml-24 "
          />
        </div>
      </div>
      <div className="w-full  flex max-[650px]:flex-col gap-20 relative items-center mt-10">
        <div className="w-full  gap-4 pl-10 ">
          <p className="text-white text-lg ">
            Every year our software developers, QA engineers, designers, and
            project managers work on hundreds of exciting projects...
          </p>
        </div>
        <div className="w-full   flex items-center  justify-center ">
          <img src={inst_vec} alt="img" className=" -mr-6 mt-8 w-10 h-10" />
          <img src={img_vec} alt="img" className="   -mr-10   z-10 w-20 h-20" />
          <img src={img2_vec} alt="img" className="   w-20 h-20" />
          <img src={img3_vec} alt="img" className="  -ml-10 w-20 h-20" />
          <img src={img4_vec} alt="img" className="  -ml-10  w-20 h-20" />
          <img src={img5_vec} alt="img" className="   -ml-10 w-20 h-20 z-10" />
          <img
            src={inst_vec}
            alt="img"
            className="  -ml-10 -mt-10  w-14 h-14"
          />
        </div>
      </div>
      <div className="w-full relative flex mt-24">
        <div className="w-  pl-10 "></div>
      </div>
      <div className="w-full  pl-10 mt-14 relative">
        <div className="w-5 h-5 rounded-full bg-green-600 absolute top-2 -left-2"></div>
        <p className="text-cyan-300 text-2xl font-medium">How we did it...</p>
        <img src={star} alt="start-img" className="w-10 my-5" />
        <div className="max-[650px]:grid-cols-1  grid grid-cols-3 gap-6 w-full  max-[650px]:px-6 max-[780px]:mt-5">
          <div
            data-aos="zoom-in"
            className="bg-neutral-700 bg-opacity-35 flex flex-col gap-3 py-6 max-[650px]:py-3  px-6 max-[950px]:px-6 max-[650px]:px-4 rounded-xl  text-white 5 w-full"
          >
            <span className="font-bold text-2xl max-[650px]:text-sm text-indigo-400">
              2014
            </span>
            <h1 className="text-xl   max-[780px]:text-sm max-[650px]:text-[15px] font-medium">
              Foundation
            </h1>
            <p className="text-sm  max-[780px]:text-xs max-[650px]:text-[10px] ">
              MapleCloud Technologies was founded to address cybersecurity
              requirements for businesses of all sizes, aiming to provide
              comprehensive and reliable solutions.
            </p>
          </div>
          <div
            data-aos="zoom-in"
            className=" bg-neutral-700 bg-opacity-35 flex flex-col gap-3 py-6 max-[650px]:py-3  px-6 max-[950px]:px-6 max-[650px]:px-4 rounded-xl  text-white 5 w-full"
          >
            <span className="font-bold text-2xl max-[650px]:text-sm text-green-400">
              2018
            </span>
            <h1 className="text-xl   max-[780px]:text-sm max-[650px]:text-[15px] font-medium">
              AgentNet (SDWAN) solutions
            </h1>

            <p className="text-sm  max-[780px]:text-xs max-[650px]:text-[10px] ">
              We introduced AgentNet (SDWAN) solutions to enable organizations
              to optimize network traffic routing based on real-time conditions,
              enhancing network performance and reliability.
            </p>
          </div>
          <div
            data-aos="zoom-in"
            className=" bg-neutral-700 bg-opacity-35 flex flex-col gap-3 py-6 max-[650px]:py-3  px-6 max-[950px]:px-6 max-[650px]:px-4 rounded-xl  text-white 5 w-full"
          >
            <span className="font-bold text-2xl max-[650px]:text-sm text-pink-400">
              2020
            </span>
            <h1 className="text-xl   max-[780px]:text-sm max-[650px]:text-[15px] font-medium">
              DevRay Launch
            </h1>
            <p className="text-sm  max-[780px]:text-xs max-[650px]:text-[10px] ">
              We launched DevRay, offering advanced security and surveillance
              solutions designed to meet the evolving needs of modern
              businesses.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full  p-14 max-[650px]:p-4 mt-14 ">
        <div className="w-full text-sm text-white bg-cyan-600 py-10 px-20 max-[650px]:px-6 rounded-2xl">
          <h1 className="text-white text-3xl font-medium max-[500px]:text-xl">
            Reflecting on an Incredible #IFSEC2024
          </h1>
          <p className="mt-6 text-white text-sm max-[500px]:text-sm">
            What an unforgettable journey at IFSEC 2024! Over three amazing
            days, MapleCloud Technologies and Devray had the privilege of
            showcasing our groundbreaking cybersecurity solutions and innovative
            products to a global audience.
          </p>

          <h1 className="text-white text-xl font-medium max-[500px]:text-xl my-1 mt-3">
            Event Highlights :
          </h1>
          <li className="mt-1">
            Insightful conversations with industry leaders, partners, and
            curious attendees.
          </li>
          <li className="mt-1">
            A fantastic platform to share our mission of creating a safer,
            smarter digital world.
          </li>
          <p className="mt-1">
            We extend our heartfelt thanks to everyone who stopped by our booth,
            attended our sessions, and supported us throughout the event. Your
            enthusiasm and curiosity drive us to innovate and grow. Though IFSEC
            2024 has come to an end, this is just the beginning of new
            partnerships, exciting opportunities, and a brighter future in
            cybersecurity. Stay tuned for updates from MapleCloud Technologies
            and Devray—we’re excited for what’s next!
          </p>

          <div className="mt-10">
            <h2 className="text-2xl font-bold text-center text-cyan-300 mb-6">
              Our Highlights
            </h2>
            <Slider {...settings}>
              {carouselItems.map((item) => (
                <div
                  key={item.id}
                  className="p-4 h-[400px] w-full text-center rounded-lg "
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    className=" w-full h-full rounded-md"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jouney;
