import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import data from "../../pages/data/Product.json";
import { useLocation } from "react-router-dom";

const EventShield = () => {
   const location = useLocation();
    const { img  } = location.state || {};
   
    const prod = {
        "id": 1,
        "img": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/Event_shield.jpg",
        "img2": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/EventShield_img.png",
        "head_name": "EventShield",
        "name": "EventShield",
        "title": "Comprehensive Cyber Threat Monitoring and Protection",
        "intro": "Introduction",
        "intro_description": "EventShield is a powerful cybersecurity solution designed to safeguard organizations against the ever-evolving landscape of cyber threats. Built for businesses of all sizes, EventShield provides continuous monitoring, proactive threat detection, and efficient response capabilities to secure valuable assets and sensitive data. With a focus on protecting critical infrastructure, EventShield enables businesses to operate confidently in today’s digital world, equipped with robust defenses that anticipate and neutralize potential threats before they can impact operations.",
        "ques": "Why EventShield?",
        "ques_description": "Cybersecurity has become a top priority for modern organizations, given the increasing frequency and complexity of cyberattacks. EventShield addresses this need by delivering comprehensive, real-time protection that goes beyond traditional security measures. Designed with adaptability and resilience in mind, EventShield helps organizations stay ahead of threats by providing continuous visibility into the security posture of their entire network. From identifying vulnerabilities to responding to incidents, EventShield empowers your security team to defend against cyber threats proactively, protecting both physical and digital assets.",
        "topics": [
          {
            "title": "Core Benefits of EventShield",
            "benefits": [
              {
                "title": "Proactive Threat Detection and Prevention :",
                "content": "EventShield uses advanced threat intelligence and machine learning algorithms to detect potential threats before they become incidents, reducing risk and improving response times."
              },
              {
                "title": "Enhanced Asset Protection :",
                "content": "With EventShield, organizations can secure all critical assets, ensuring data integrity and minimizing vulnerabilities across their network."
              },
              {
                "title": "24/7 Monitoring and Response :",
                "content": "Gain round-the-clock monitoring of all network activities, ensuring threats are detected in real-time and addressed swiftly, reducing exposure and minimizing damage."
              },
              {
                "title": "Centralized Security Management :",
                "content": "EventShield centralizes all security data into a single interface, allowing teams to monitor, assess, and respond from one place, improving efficiency and streamlining workflows."
              },
              {
                "title": "Scalable Solution for Growing Businesses :",
                "content": "EventShield grows with your organization, providing flexibility and scalability as your cybersecurity needs evolve."
              }
            ]
          },
          {
            "title": "Standout Features of EventShield",
            "benefits": [
              {
                "title": "Advanced Threat Intelligence :",
                "content": " EventShield integrates global threat intelligence, combining data from various sources to stay updated on the latest threat vectors. This allows organizations to anticipate and defend against even the newest cyber threats."
              },
              {
                "title": "Real-Time Alerts and Notifications :",
                "content": "EventShield provides customizable alerts and notifications, ensuring your security team is immediately informed of any potential threats or unusual activities."
              },
              {
                "title": "Automated Incident Response :",
                "content": "Streamline and accelerate response times with EventShield’s automated incident response capabilities, enabling quicker resolutions and reducing the impact of cyber incidents on business operations."
              },
              {
                "title": "Behavioral Analytics :",
                "content": " EventShield employs behavioral analytics to detect suspicious activities within your network. By identifying deviations from typical behavior, EventShield can identify potential threats that traditional security systems might miss."
              },
              {
                "title": "Comprehensive Reporting and Compliance :",
                "content": "Generate detailed reports and trackcompliance metrics with EventShield’s reporting tools. Keep stakeholders informed and ensure adherence to industry standards and regulatory requirements with ease."
              },
              {
                "title": "Integration with Existing Security Tools :",
                "content": " EventShield seamlessly integrates with existing security systems, such as SIEM, firewall solutions, and endpoint protection tools, providing a cohesive, layered security strategy."
              }
            ]
          }
        ]
      }
  return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-full max-[900px]:w-full flex flex-col  gap-5 text-white text-md p-8 text-md">
        <div className="w-full flex items-center justify-center">
          <img
            src={img}
            alt="img"
            loading="lazy"
            className="w-4/5 max-[400px]:w-full h-[380px] rounded-xl"
          />
        </div>
        <div className="w-full flex text-lg items-center  gap-2">
          {prod.head_name} : {prod.title}
        </div>
        <h1 className="text-white text-2xl font-medium">Introduction</h1>
        <p>{prod.intro_description}</p>
        <h1 className="text-white text-2xl font-medium">{prod.ques}</h1>
        <p>{prod.ques_description}</p>
        {prod.topics.map((data) => (
          <div key={data.id} className="w-full flex flex-col gap-4">
            <h1 className="text-white text-2xl font-medium">{data.title}</h1>

            {data.benefits.map((point, index) => (
              <li key={index}>
                <span className="font-medium text-white"> {point.title}</span>{" "}
                {point.content}
              </li>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default EventShield 
