import React, { useState } from "react";
import img from "../images/faq_img.png";

function Faq() {
  const [openIndex, setOpenIndex] = useState(null); // Track which FAQ is open

  const toggle = (id) => {
    // Log the state and the ID being toggled
    console.log(`Toggling FAQ with ID: ${id}`);
    console.log(`Previous openIndex: ${openIndex}`);

    // Toggle visibility of the FAQ answer based on the clicked question
    setOpenIndex((prevIndex) => (prevIndex === id ? null : id));

    // Log the updated openIndex state
    console.log(`Updated openIndex: ${openIndex}`);
  };

  const content = [
    {
      id: 1,
      ques: "What cybersecurity services does MapleCloud Technologies provide?",
      ans: "We offer a comprehensive suite of cybersecurity solutions, including: Next-Generation Firewalls (NGFW), Network Intrusion Detection and Prevention Systems, Endpoint Security Solutions, Data Encryption and Secure Backup, Vulnerability Assessment and Penetration Testing (VAPT), Managed Security Services (MSSP).",
    },
    {
      id: 2,
      ques: "What is a Next-Generation Firewall (NGFW), and how does it help my business?",
      ans: "An NGFW combines traditional firewall features with advanced capabilities such as deep packet inspection, intrusion prevention, and application control. It provides robust protection against modern cyber threats like malware, ransomware, and phishing attacks.",
    },
    {
      id: 3,
      ques: "Do you offer 24/7 cybersecurity monitoring?",
      ans: "Yes, our Managed Security Services include 24/7 monitoring of your systems, real-time threat detection, and immediate response to potential threats.",
    },
    {
      id: 4,
      ques: "How does MapleCloud ensure data security?",
      ans: "We use industry-leading encryption standards, multi-factor authentication, secure access controls, and compliance with global regulations such as GDPR, CCPA, and HIPAA to safeguard your data.",
    },
    {
      id: 5,
      ques: "Can you help my business recover from a data breach?",
      ans: "Yes, our incident response team provides rapid support to identify, contain, and mitigate the effects of a data breach, helping you recover quickly and strengthen your defenses.",
    },
  ];

  return (
    <div className="pt-32 pb-5 bg-gray-950 w-full grid grid-cols-1 md:grid-cols-2 justify-between items-start">
      <div className="content max-[400px]:px-4 w-full px-10 flex flex-col gap-8 h-full text-white">
        <div className="vector border"></div>
        <div className="w-full text-3xl">
          <p className="leading-20 font-semibold">
            FREQUENTLY <br /> ASK{" "}
            <span className="text-sky-400">QUESTIONS</span>
          </p>
        </div>
        <div className="w-full flex flex-col gap-4 z-10  transition-all ease-in-out duration-300">
          {/* FAQ Item */}
          {content.map((data) => (
            <div
              className="w-full border border-gray-700 rounded-lg"
              key={data.id}
            >
              <div className="w-full flex items-center cursor-pointer">
                <div className="w-[5%] px-3">
                  {data.id < 9 ? `0${data.id}` : `${data.id}`}
                </div>
                <div className="w-full px-2 text-sm max-[500px]:text-xs ml-2">
                  {data.ques}
                </div>
                <div
                  className="bg-sky-400 py-2 rounded-r-md px-4 text-2xl font-bold cursor-pointer"
                  onClick={() => toggle(data.id)} // Toggle the answer visibility
                >
                  {openIndex === data.id ? "-" : "+"}{" "}
                  {/* Change + to - when open */}
                </div>
              </div>
              {/* Only show answer if the FAQ is open */}
              {openIndex === data.id && (
                <div className="px-4 text-sm py-4">{data.ans}</div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="w-full max-[760px]:hidden  h-4/5 flex items-start justify-center">
        <img src={img} alt="img" className="h-[500px] w-[450px]" />
      </div>
    </div>
  );
}

export default Faq;
