import React from "react";
import { useLocation } from "react-router-dom";

const NCMS = () => {
   const location = useLocation();
    const { img  } = location.state || {};
   
    const prod = {
      "id": 6,
      "img": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/ncms.jpg",
      "img2": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/mct-ncms.jpg",
      "head_name": "MCT NCMS",
      "name": "MCT NCMS",
      "title": "Comprehensive Network Configuration Management for Seamless Operations",
      "intro": "Introduction",
      "intro_description": "MCT NCMS (Network Configuration Management Solution) is an advanced tool developed to streamline network configuration management across a diverse range of devices and infrastructure types. With MCT NCMS, businesses can simplify the complexities of network administration, automate configuration tasks, and maintain consistent performance standards, regardless of the devices or vendors in use. This robust solution is tailored to the needs of modern enterprises, providing a reliable platform for managing network configurations, tracking changes, and enhancing network security.",
      "ques": "Why MCT NCMS?",
      "ques_description": "Managing network configurations manually can be challenging, especially when dealing with multiple device types and complex infrastructure setups. MCT NCMS was built to address these challenges by offering a centralized, automated solution that handles configuration tasks efficiently and securely. By providing visibility into device configurations and simplifying change management, MCT NCMS empowers businesses to reduce downtime, enhance network security, and achieve operational efficiency.",
      "topics": [
        {
          "title": "Core Benefits of NCMS",
          "benefits": [
            {
              "title": "Centralized Network Controlr :",
              "content": "MMCT NCMS consolidates configuration management, enabling administrators to control and manage all network devices from a singleplatform, reducing the need for multiple management tools."
            },
            {
              "title": "Enhanced Network Security :",
              "content": "With robust configuration backup and recovery features, MCT NCMS protects against unauthorized changes and allows for rapid restoration in the event of an issue."
            },
            {
              "title": "Operational Efficiency :",
              "content": " By automating repetitive configuration tasks, MCT NCMS reduces the risk of errors and saves valuable time, enabling IT teams to focus on strategic initiatives."
            },
            {
              "title": "Reduced Downtime :",
              "content": "MCT NCMS’s proactive monitoring and change tracking features help identify and resolve issues before they lead to downtime, keeping networks reliable and available."
            },
            {
              "title": "Vendor-Neutral Integration :",
              "content": "MCT NCMS seamlessly integrates with a wide variety of devices, regardless of the vendor, ensuring compatibility with diverse network environments."
            }
          ]
        },
        {
          "title": "Standout Features of MCT NCMS",
          "benefits": [
            {
              "title": "Automated Configuration Management :",
              "content": "Schedule, automate, and deploy configuration changes across devices effortlessly, ensuring consistent updates and eliminating manual errors."
            },
            {
              "title": "Configuration Backup and Restore :",
              "content": "Regularly back up device configurations and restore them quickly if needed. This feature minimizes risk and provides rapid recovery from accidental changes or misconfigurations."
            },
            {
              "title": "Change Tracking and Version Control:",
              "content": " Track and log every change made to network configurations, making it easy to review, audit, and roll back to previous configurations if needed."
            },
            {
              "title": "Real-Time Alerts and Notifications :",
              "content": " Receive instant alerts when configuration changes are detected, helping administrators respond quickly to unauthorized modifications or potential security threats."
            },
            {
              "title": "Compliance and Audit Reporting :",
              "content": " MCT NCMS generates detailed compliance and audit reports, making it easier to meet regulatory requirements and maintain industry standards."
            },
            {
              "title": "Multi-Vendor Compatibility : ",
              "content": "MCT NCMS is designed to work with network devices from a wide range of vendors, providing flexibility and ensuring seamless integration across heterogeneous network environments."
            },
            {
              "title": "Role-Based Access Control (RBAC):",
              "content": "Enhance security by assigning specific roles and access permissions, allowing only authorized users to make configuration changes."
            },
            {
              "title": "Intuitive Dashboard and User Interface :",
              "content": " MCT NCMS offers a user-friendly interface with customizable dashboards, providing a clear, organized view of network configurations and key metrics."
            }
          ]
        }
      ]
    }
  return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-full max-[900px]:w-full flex flex-col  gap-5 text-white text-md p-8 text-md">
        <div className="w-full flex items-center justify-center">
          <img
            src={img}
            alt="img"
            loading="lazy"
            className="w-4/5 max-[400px]:w-full h-[380px] rounded-xl"
          />
        </div>
        <div className="w-full flex text-lg items-center  gap-2">
          {prod.head_name} : {prod.title}
        </div>
        <h1 className="text-white text-2xl font-medium">Introduction</h1>
        <p>{prod.intro_description}</p>
        <h1 className="text-white text-2xl font-medium">{prod.ques}</h1>
        <p>{prod.ques_description}</p>
        {prod.topics.map((data) => (
          <div key={data.id} className="w-full flex flex-col gap-4">
            <h1 className="text-white text-2xl font-medium">{data.title}</h1>

            {data.benefits.map((point, index) => (
              <li key={index}>
                <span className="font-medium text-white"> {point.title}</span>{" "}
                {point.content}
              </li>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default NCMS; 
