import React from "react";
import { useLocation } from "react-router-dom";

const MCTAuth = () => {
   const location = useLocation();
    const { img  } = location.state || {};
   
    const prod =  {
      "id": 5,
      "img": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/mail_bridges.jpg",
      "img2": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/mail.jpg",
      "head_name": "MailBridges",
      "name": "MCT MailBridges",
      "title": " A Robust Email Server Solution for Modern Communication Needs",
      "intro": "Introduction",
      "intro_description": "MCT MailBridges is a comprehensive email server solution designed to provide reliable, secure, and feature-rich email services for businesses. With an integrated webmail client and support for essential email protocols, including SMTP, POP3, and IMAP, MailBridges ensures seamless communication across various devices and platforms. Supporting multiple domains, users, and administrators, MCT MailBridges is a scalable solution ideal for organizations of any size, offering centralized email management and streamlined communication.",
      "ques": "Why MailBridges?",
      "ques_description": "Email remains one of the primary methods of business communication, making a reliable, secure, and manageable email server solution essential. MCT MailBridges offers a robust platform that combines functionality and ease of use, allowing organizations to manage multiple email domains and users effortlessly. With MCT MailBridges, businesses can secure email communications, reduce downtime, and enjoy flexible configuration options. It’s the perfect solution for organizations that require control, customization, and a seamless user experience across their email environment.",
      "topics": [
        {
          "title": "Core Benefits of MailBridges",
          "benefits": [
            {
              "title": "Full-Featured Email Server :",
              "content": "MCT MailBridges provides all the tools needed to manage and maintain a robust email environment, reducing dependence on third-party services and providing full control over email communication."
            },
            {
              "title": "Enhanced Security and Privacy :",
              "content": "MailBridges includes advanced security features such as spam filtering, encryption, and access controls, ensuring that email communications remain safe and compliant with privacy standards."
            },
            {
              "title": "Centralized Administration :",
              "content": " With support for multiple domains, users, and administrators, MCT MailBridges makes it easy to manage email infrastructure from a single, centralized location."
            },
            {
              "title": "Protocol Flexibility :",
              "content": "Supporting SMTP, POP3, and IMAP, MCT MailBridges enables compatibility with various devices and clients, providing a seamless email experience for end-users."
            },
            {
              "title": "Scalability :",
              "content": "Whether your organization is small or large, MCT MailBridges scales with your needs, allowing you to add new domains, users, and administrators as your business grows."
            }
          ]
        },
        {
          "title": "Standout Features of MCT MailBridges",
          "benefits": [
            {
              "title": "Integrated Webmail Client :",
              "content": "MCT MailBridges includes a user-friendly webmail client, providing users with quick, convenient access to their email from any web browser, anywhere."
            },
            {
              "title": "Support for Multiple Domains :",
              "content": "Manage email for multiple domains from a single platform, ideal for organizations with different business units or for hosting environments supporting multiple clients."
            },
            {
              "title": "Flexible User and Admin Roles:",
              "content": " MCT MailBridges allows for role-based access, enabling administrators to manage permissions and assign roles to users with ease, enhancing security and simplifying management."
            },
            {
              "title": "Advanced Spam and Virus Filtering :",
              "content": "Protect your inboxes with powerful spam and virus filtering tools. MCT MailBridges identifies and blocks suspicious emails, minimizing security risks and ensuring a cleaner, safer inbox."
            },
            {
              "title": "Customizable Email Policie :",
              "content": "Tailor your email system to meet specific organizational requirements with customizable policies for mailbox sizes, attachment limits, and user restrictions."
            },
            {
              "title": "Seamless IMAP, POP3, and SMTP Support : ",
              "content": "With support for essential email protocols, MCT MailBridges enables access from any compatible email client, providing flexibility for desktop and mobile email users."
            },
            {
              "title": "Data Backup and Recovery :",
              "content": "Keep email data secure with regular backups and recovery options, ensuring that important communications are never lost and can be restored in the event of a server issue."
            },
            {
              "title": "Detailed Reporting and Analytics :",
              "content": "Gain insights into email usage, storage, and performance with detailed reporting tools, enabling administrators to monitor system health and optimize email operations."
            }
          ]
        }
      ]
    }
  return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-full max-[900px]:w-full flex flex-col  gap-5 text-white text-md p-8 text-md">
        <div className="w-full flex items-center justify-center">
          <img
            src={img}
            alt="img"
            loading="lazy"
            className="w-4/5 max-[400px]:w-full h-[380px] rounded-xl"
          />
        </div>
        <div className="w-full flex text-lg items-center  gap-2">
          {prod.head_name} : {prod.title}
        </div>
        <h1 className="text-white text-2xl font-medium">Introduction</h1>
        <p>{prod.intro_description}</p>
        <h1 className="text-white text-2xl font-medium">{prod.ques}</h1>
        <p>{prod.ques_description}</p>
        {prod.topics.map((data) => (
          <div key={data.id} className="w-full flex flex-col gap-4">
            <h1 className="text-white text-2xl font-medium">{data.title}</h1>

            {data.benefits.map((point, index) => (
              <li key={index}>
                <span className="font-medium text-white"> {point.title}</span>{" "}
                {point.content}
              </li>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default MCTAuth; 
