import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import data from "../../pages/data/Product.json";
import { useLocation } from "react-router-dom";

const MapleDoc = () => {
   const location = useLocation();
    const { img  } = location.state || {};
   
    const prod = {
      id: 3,
      head_name: "Maple Doc",
      img: "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/Maple-doc.jpg",
      img2: "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/Maple_doc.avif",
      name: "Maple Doc",
      title: "Comprehensive Document Management System (DMS) for Modern Data Needs",
      intro: "Introduction",
      intro_description:
        "Maple Doc by MapleCloud Technologies is an advanced Document Management System (DMS) designed to streamline the way organizations manage, store, and access documents. With a user-friendly interface and powerful functionality, Maple Doc empowers businesses to efficiently create, modify, retrieve, and organize documents. This versatile DMS software supports seamless document structuring and categorization, making it an ideal choice for organizations of all sizes aiming to optimize document workflows. Whether you're handling large volumes of files or managing complex documentation processes, Maple Doc provides the tools needed for efficient, secure, and accessible document management.",
      ques: "Why Maple Doc?",
      ques_description:
        "In a world where documentation is critical for success, having a reliable and intuitive DMS is essential for businesses seeking to leverage their data effectively. Maple Doc offers a comprehensive solution that reduces the complexity of document management, allowing users to focus on insights and strategic decision-making rather than manual administrative tasks. With robust control features, Maple Doc enhances productivity, streamlines processes, and provides a secure environment for handling sensitive documents. Its adaptable design fits various industries and use cases, making it an invaluable asset for any organization prioritizing document integrity, security, and accessibility.",
      topics: [
        {
          title: "Core Benefits of Maple Doc",
          benefits: [
            {
              title: "Efficient Document Management:",
              content:
                "Simplifies the handling of documents, enabling quick and accurate operations. This efficiency leads to improved workflows and faster decision-making.",
            },
            {
              title: "User-Friendly Interface:",
              content:
                "Built for ease of use, Maple Doc features an intuitive interface that reduces the learning curve, allowing users of all skill levels to navigate and perform complex tasks effortlessly.",
            },
            {
              title: "Enhanced Document Accessibility:",
              content:
                "Facilitates seamless access and retrieval of documents, enabling better collaboration and quicker insights across teams.",
            },
            {
              title: "Robust Security Measures:",
              content:
                "Designed with security at its core, Maple Doc includes advanced access controls and compliance features to safeguard sensitive information from unauthorized access and breaches.",
            },
            {
              title: "Customizable Structures and Categories:",
              content:
                "Provides the flexibility to define and tailor document structures and categories, meeting the unique requirements of diverse organizations.",
            },
          ],
        },
        {
          title: "Standout Features of Maple Doc",
          benefits: [
            {
              title: "Comprehensive Document Manipulation:",
              content:
                "Tools for creating, editing, organizing, and deleting documents ensure precision and efficiency in managing files.",
            },
            {
              title: "Advanced Categorization and Metadata Management:",
              content:
                "Simplify the organization of large document collections with powerful categorization and metadata tools, essential for structured retrieval and storage.",
            },
            {
              title: "Seamless Document Retrieval:",
              content:
                "Optimized search functions allow fast and reliable document access, ensuring information is available when needed.",
            },
            {
              title: "Scalability:",
              content:
                "Designed to grow with your organization, Maple Doc accommodates increasing volumes of documents and users, making it a sustainable long-term solution.",
            },
            {
              title: "Access Control and Compliance:",
              content:
                "Features robust role-based access controls, ensuring sensitive information remains secure and compliant with regulatory standards.",
            },
            {
              title: "Real-Time Collaboration and Annotations:",
              content:
                "Enhance teamwork with built-in collaboration tools, including document sharing, commenting, and version control.",
            },
            {
              title: "Document Backup and Recovery:",
              content:
                "Protect critical files with reliable backup and recovery options, ensuring continuity even in the event of system failures or unexpected events.",
            },
          ],
        },
      ],
    };
  return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-full max-[900px]:w-full flex flex-col  gap-5 text-white text-md p-8 text-md">
        <div className="w-full flex items-center justify-center">
          <img
            src={img}
            alt="img"
            loading="lazy"
            className="w-4/5 max-[400px]:w-full h-[380px] rounded-xl"
          />
        </div>
        <div className="w-full flex text-lg items-center  gap-2">
          {prod.head_name} : {prod.title}
        </div>
        <h1 className="text-white text-2xl font-medium">Introduction</h1>
        <p>{prod.intro_description}</p>
        <h1 className="text-white text-2xl font-medium">{prod.ques}</h1>
        <p>{prod.ques_description}</p>
        {prod.topics.map((data) => (
          <div key={data.id} className="w-full flex flex-col gap-4">
            <h1 className="text-white text-2xl font-medium">{data.title}</h1>

            {data.benefits.map((point, index) => (
              <li key={index}>
                <span className="font-medium text-white"> {point.title}</span>{" "}
                {point.content}
              </li>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default MapleDoc;
