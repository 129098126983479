import React, { useState, useEffect, useRef } from "react";
import ChatMessage from "./ChatMessage";
import ChatInput from "./ChatInput";
import "./ChatWindow.css";
import { IoIosCloseCircleOutline } from "react-icons/io";

const ChatWindow = ({ closeChat }) => {
  const [messages, setMessages] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isFinalMessageShown, setIsFinalMessageShown] = useState(false);
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const messagesEndRef = useRef(null);

  const baseQuestions = [
    {
      type: "options",
      text: "How can I help you today?",
      options: [
        { key: "specialist_1", label: "Hi! I want to speak to a specialist?" },
        { key: "specialist_2", label: "Hi! I here to explore solutions?" },
      ],
    },
  ];

  const branchQuestions = {
    specialist_1: [
      { type: "text", text: "Hi! What we may address you?" },
      {
        type: "text",
        text: "Hi! to take for further follow up please provide your email id?",
      },
      { type: "text", text: "Thanks! could you provide phone number?" },
      { type: "text", text: "Explain yor concern in few words?" },
      //   { type: "text", text: "We will connect you shortly." },
    ],
    specialist_2: [
      {
        type: "text",
        text: "Hi! to take for further follow up please provide your email id?",
      },
      {
        type: "options",
        text: "Choose your concern?",
        options: [
          {
            key: "network",
            label: "Network Security",
          },
          { key: "cyber", label: "Cyber Security" },
          { key: "business", label: "Business Management Solutions" },
          { key: "service", label: "Service" },
        ],
      },
    ],
    network: [
      {
        type: "options",
        text: "Select from below options",
        options: [
          { key: "nac", label: "NAC (Network Access Control)" },
          { key: "sdwan", label: "SDWAN (Software Development WAN)" },
          { key: "nms", label: "NMS (Network Monitoring System)" },
          { key: "log", label: "MapleLog (Log Management)" },
          { key: "firewall", label: "Firewall" },
          { key: "switch", label: "Switch" },
        ],
      },
    ],
    cyber: [
      {
        type: "options",
        text: "Select from below options",
        options: [
          {
            key: "siem",
            label: "SIEM (Security Information & Event Management)",
          },
          { key: "va", label: "VA (Vulnerability Assessment)" },
          { key: "frs", label: "FRS (Face Recognition system)" },
          { key: "xdr", label: "XDR (Entended Detection & Response)" },
        ],
      },
    ],
    business: [
      {
        type: "options",
        text: "Select from below options",
        options: [
          { key: "hrms", label: "HRMS (Human Resource Management System)" },
          { key: "dms", label: "DMS (Document Management System)" },
          { key: "lms", label: "LMS (Learning Management System)" },
          { key: "pms", label: "PMS (Project Management System)" },
        ],
      },
    ],
    service: [
      { type: "text", text: "Are you looking for managed IT services?" },
      { type: "text", text: "Do you need on-demand support?" },
    ],
  };

  // console.log(messages);

  useEffect(() => {
    if (messages.length === 0 && baseQuestions.length > 0) {
      setMessages([{ type: "bot", text: baseQuestions[0].text }]);
      setCurrentQuestions(baseQuestions);
    }
  }, [messages]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // const handleUserResponse = (response) => {
  //   const userMessage = { type: "user", text: response };
  //   const nextQuestionIndex = currentQuestionIndex + 1;

  //   setMessages((prevMessages) =>
  //     [
  //       ...prevMessages,
  //       userMessage,
  //       currentQuestions[nextQuestionIndex]
  //         ? { type: "bot", text: currentQuestions[nextQuestionIndex].text }
  //         : null,
  //     ].filter(Boolean)
  //   );

  //   setCurrentQuestionIndex(nextQuestionIndex);

  //   if (nextQuestionIndex === currentQuestions.length) {
  //     setIsFinalMessageShown(true);
  //   }
  // };

  const handleUserResponse = (response) => {
    if (
      currentQuestions[currentQuestionIndex].text.includes("email") &&
      !/\S+@\S+\.\S+/.test(response)
    ) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: "bot", text: "Please enter a valid email address." },
      ]);
      return;
    }

    const userMessage = { type: "user", text: response };
    const nextQuestionIndex = currentQuestionIndex + 1;

    setMessages((prevMessages) =>
      [
        ...prevMessages,
        userMessage,
        currentQuestions[nextQuestionIndex]
          ? { type: "bot", text: currentQuestions[nextQuestionIndex].text }
          : null,
      ].filter(Boolean)
    );

    setCurrentQuestionIndex(nextQuestionIndex);

    if (nextQuestionIndex === currentQuestions.length) {
      setIsFinalMessageShown(true);
    }
  };

  const handleOptionClick = (optionKey, label) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "user", text: label },
    ]);

    if (optionKey === "nac") {
      window.open(
        "https://www.maplecloudtechnologies.com/prod_detail/8",
        "_blank"
      );
      //   return;
    } else if (optionKey === "sdwan") {
      window.open(
        "https://www.maplecloudtechnologies.com/prod_detail/7",
        "_blank"
      );
    } else if (optionKey === "nms") {
      window.open(
        "https://www.maplecloudtechnologies.com/prod_detail/4",
        "_blank"
      );
    } else if (optionKey === "log") {
      window.open(
        "https://www.maplecloudtechnologies.com/prod_detail/9",
        "_blank"
      );
    } else if (optionKey === "siem") {
      window.open(
        "https://www.maplecloudtechnologies.com/prod_detail/1",
        "_blank"
      );
    }

    if (branchQuestions[optionKey]) {
      setCurrentQuestions(branchQuestions[optionKey]);
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: "bot", text: branchQuestions[optionKey][0].text },
      ]);
      setCurrentQuestionIndex(0);
    } else {
      setIsFinalMessageShown(true);
    }
  };

  // const handleOptionClick = (optionKey, label) => {
  //   setMessages((prevMessages) => [
  //     ...prevMessages,
  //     { type: "user", text: label },
  //   ]);

  //   if (branchQuestions[optionKey]) {
  //     setCurrentQuestions([
  //       ...branchQuestions[optionKey],
  //       { type: "text", text: "Hi! What we may address you?" },
  //       {
  //         type: "text",
  //         text: "Hi! to take for further follow up please provide your email id?",
  //       },
  //     ]);
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       { type: "bot", text: branchQuestions[optionKey][0].text },
  //     ]);
  //     setCurrentQuestionIndex(0);
  //   } else {
  //     setIsFinalMessageShown(true);
  //   }
  // };

  const handleSubmit = async () => {
    console.log(messages);
    try {
      const res = await fetch("http://122.160.42.185:8081/api/lead/lead", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messages),
      });

      if (!res.ok) {
        const errorData = await res.json();
        console.error("Error response:", errorData);
      } else {
        const data = await res.json();
        console.log("Success response:", data);
      }
    } catch (error) {
      console.log("Error in fetch:", error);
    }
  };

  useEffect(() => {
    if (isFinalMessageShown) {
      handleSubmit();
    }
  }, [isFinalMessageShown]);

  return (
    <div className="chat-window">
      <div className="chat-header">
        <span>MapleChat</span>
        <button onClick={closeChat}>
          <IoIosCloseCircleOutline size={24} />
        </button>
      </div>
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <ChatMessage key={index} message={msg} />
        ))}
        {isFinalMessageShown && (
          <>
            <ChatMessage
              message={{
                type: "bot",
                text: "Thank you for your responses, our team will reach out to you soon!",
              }}
            />
          </>
        )}
        <div ref={messagesEndRef} />
      </div>
      {currentQuestionIndex < currentQuestions.length &&
        !isFinalMessageShown && (
          <>
            {currentQuestions[currentQuestionIndex].type === "options" ? (
              <div className="options">
                {currentQuestions[currentQuestionIndex].options.map(
                  (option, index) => (
                    <button
                      key={option.key}
                      onClick={() =>
                        handleOptionClick(option.key, option.label)
                      }
                    >
                      {option.label}
                    </button>
                  )
                )}
              </div>
            ) : (
              <ChatInput
                question={currentQuestions[currentQuestionIndex]}
                onSend={handleUserResponse}
              />
            )}
          </>
        )}
    </div>
  );
};

export default ChatWindow;
