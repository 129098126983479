import React from "react";
import { useLocation } from "react-router-dom";

const Log = () => {
   const location = useLocation();
    const { img  } = location.state || {};
   
    const prod =  {
      "id": 9,
      "img": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/mpale_log.jpg",
      "img2": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/map-log.jpg",
      "head_name": "Maple Logs",
      "name": "Maple Logs",
      "title": "Secure, Control, and Monitor Network Access",
      "intro": "Introduction",
      "intro_description": "Maple Logs (MapLog), developed by MapleCloud Technologies, is a powerful, proprietary log management platform crafted to meet the needs of organizations handling large volumes of log data. Engineered to process and analyze data in real time, Maple Log allows businesses to gather, store, and manage terabytes of machine-generated data effortlessly. By leveraging cutting-edge data processing and analytics technologies, Maple Log provides critical insights, enhances ecision-making, and supports operational efficiency and compliance with an easy-to-use, scalable solution.",
      "ques": "Why Maple Log?",
      "ques_description": "In today’s data-driven world, having a robust log management solution is essential to ensure visibility into system performance, security, and compliance. Maple Log stands out as an efficient, scalable solution that enables organizations to make sense of vast amounts of log data. By consolidating and analyzing logs from multiple sources, Maple Log enables IT teams to proactively detect issues, improve security posture, and optimize operational efficiency. Its ability to deliver actionable insights in real time empowers organizations to make data-informed decisions faster and more accurately.",
      "topics": [
        {
          "title": "Core Benefits of  Maple Log",
          "benefits": [
            {
              "title": "Improved Decision-Making :",
              "content": "Maple Log transforms raw log data into meaningful insights, helping organizations make faster, data-informed decisions to improve operations and security."
            },
            {
              "title": "Enhanced Security and Compliance :",
              "content": "By monitoring logs in real time, Maple Log helps detect potential security threats and supports regulatory compliance with thorough audit trails and reporting."
            },
            {
              "title": "Centralized Log Management :",
              "content": "Maple Log consolidates log data from various sources into a single, accessible platform, simplifying management and improving data visibility across the organization."
            },
            {
              "title": "Reduced Downtime :",
              "content": "Maple Log’s real-time monitoring and alerting capabilities allow IT teams to identify and resolve issues before they impact operations, reducing system downtime and enhancing erformance."
            },
            {
              "title": "Scalable for Large Data Volumes :",
              "content": "Designed to handle terabytes of data, Maple Log scales with your organization, accommodating growth without compromising performance or accessibility."
            }
          ]
        },
        {
          "title": "Standout Features of Maple Logs",
          "benefits": [
            {
              "title": "Real-Time Log Analysis :",
              "content": "Maple Log continuously collects and analyzes log data as it is generated, providing immediate insights and enabling rapid response to emerging issues."
            },
            {
              "title": "Advanced Search and Filtering :",
              "content": "Quickly find specific log entries with powerful search and filtering capabilities, allowing administrators to drill down into data and identify patterns or anomalies."
            },
            {
              "title": "Automated Alerts and Notifications :",
              "content": "  Set up customized alerts for critical events, such as security breaches or performance bottlenecks, ensuring your team is informed instantly when action is needed."
            },
            {
              "title": "Intuitive Dashboard and Visualizations :",
              "content": "Access log data through a user-friendly dashboard with rich visualizations, making it easy to understand trends, detect anomalies, and analyze system performance."
            },
            {
              "title": "Seamless Integration :",
              "content": "Maple Log integrates with various data sources and systems, ensuring compatibility with your existing infrastructure and delivering a cohesive view of your IT environment."
            },
            {
              "title": "Data Retention and Compliance Support : ",
              "content": "Comply with regulatory requirements by defining retention policies and archiving logs for as long as necessary, supporting audits and investigations with comprehensive data history."
            },
            {
              "title": "Role-Based Access Control (RBAC) :",
              "content": "Protect sensitive log data by assigning access based on roles, allowing only authorized users to view and manage log data."
            },
            {
              "title": "Comprehensive Reporting and Audit Trails :",
              "content": "Generate detailed reports for compliance, auditing, and performance tracking, supporting transparency and accountability across the organization."
            }
          ]
        }
      ]
    }
  return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-full max-[900px]:w-full flex flex-col  gap-5 text-white text-md p-8 text-md">
        <div className="w-full flex items-center justify-center">
          <img
            src={img}
            alt="img"
            loading="lazy"
            className="w-4/5 max-[400px]:w-full h-[380px] rounded-xl"
          />
        </div>
        <div className="w-full flex text-lg items-center  gap-2">
          {prod.head_name} : {prod.title}
        </div>
        <h1 className="text-white text-2xl font-medium">Introduction</h1>
        <p>{prod.intro_description}</p>
        <h1 className="text-white text-2xl font-medium">{prod.ques}</h1>
        <p>{prod.ques_description}</p>
        {prod.topics.map((data) => (
          <div key={data.id} className="w-full flex flex-col gap-4">
            <h1 className="text-white text-2xl font-medium">{data.title}</h1>

            {data.benefits.map((point, index) => (
              <li key={index}>
                <span className="font-medium text-white"> {point.title}</span>{" "}
                {point.content}
              </li>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default Log; 
