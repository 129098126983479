import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import data from "../../pages/data/Product.json";
import { useLocation } from "react-router-dom";

const MapleDesk = () => {
   const location = useLocation();
    const { img  } = location.state || {};
  const prod = {
    id: 10,
    img: "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/maple_doc.jpg",
    img2: "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/maple_desk.png",
    head_name: "Maple Desk",
    name: "Maple Desk",
    title: "Streamlined, Powerful Service Management for the Modern Business",
    intro: "Introduction",
    intro_description:
      "Maple Desk is advanced Service Management software that brings unparalleled efficiency, flexibility, and control to your IT operations. Designed with ease of use and scalability in mind, Maple Desk is the ultimate solution for organizations aiming to optimize IT service delivery, ensure seamless workflows, and foster a proactive IT environment.",
    ques: "Why Maple Desk?",
    ques_description:
      "Managing IT infrastructure effectively is crucial to sustaining and growing a business in today's digital world. Maple Desk offers a complete suite of tools to help your team work smarter, faster, and more collaboratively. With an intuitive interface and robust functionality, Maple Desk adapts to your unique requirements, ensuring your IT services are always a step ahead.",
    topics: [
      {
        title: "Core Benefits of Maple Desk",
        benefits: [
          {
            title: "Effortless Change Management:",
            content:
              "Maple Desk simplifies the process of planning, executing, and monitoring IT changes, significantly reducing operational risks and ensuring continuity. Change managers can easily approve, track, and review changes, minimizing disruptions to critical services.",
          },
          {
            title: "Intelligent Problem Resolution:",
            content:
              "With Maple Desk, solving problems becomes a swift, structured process. Our software prioritizes issues, allowing IT teams to handle critical tasks first while automating routine maintenance. Maple Desk reduces time-to-resolution, enhances customer satisfaction, and keeps operations running smoothly.",
          },
          {
            title: "Enhanced Automation:",
            content:
              "Take your IT operations to the next level with Maple Desk’s robust automation capabilities. From automating ticket generation to recurring tasks, Maple Desk frees up your team’s time for more strategic initiatives, reducing human error and operational costs.",
          },
          {
            title: "Complete IT Control and Transparency:",
            content:
              "Maple Desk provides a centralized view of your IT landscape. Track configurations, monitor assets, manage incidents, and maintain compliance effortlessly. Comprehensive reporting and analytics offer real-time insights that empower better decision-making.",
          },
        ],
      },
      {
        title: "Maple Desk’s Standout Features",
        benefits: [
          {
            title: "Advanced Ticketing System:",
            content:
              "A highly configurable ticketing module enables you to streamline request handling, prioritize urgent issues, and provide faster resolutions.",
          },
          {
            title: "Asset and Inventory Management:",
            content:
              "Keep track of every IT asset within your organization, from hardware to software licenses. Maple Desk's asset management feature offers visibility over asset lifecycle and location, helping you maximize value from your IT investments.",
          },
          {
            title: "Service-Level Management:",
            content:
              "Define, monitor, and report on your Service Level Agreements (SLAs) with Maple Desk’s SLA management feature. Track performance, meet targets, and demonstrate your commitment to service excellence.",
          },
          {
            title: "Change and Configuration Management:",
            content:
              "Maple Desk offers powerful tools to log, assess, and approve IT changes, ensuring seamless configurations and reducing risk of unplanned downtime.",
          },
          {
            title: "Analytics and Reporting:",
            content:
              "Gain actionable insights with Maple Desk’s comprehensive analytics. Generate custom reports on metrics like incident trends, service performance, asset usage, and SLA compliance, empowering data-driven IT strategy.",
          },
          {
            title: "Self-Service Portal:",
            content:
              "Empower users with a user-friendly self-service portal. Employees can submit requests, track progress, and access FAQs, boosting efficiency and reducing the workload on IT teams.",
          },
          {
            title: "Role-Based Access Control (RBAC):",
            content:
              "Protect sensitive log data by assigning access based on roles, allowing only authorized users to view and manage log data.",
          },
          {
            title: "Comprehensive Reporting and Audit Trails:",
            content:
              "Generate detailed reports for compliance, auditing, and performance tracking, supporting transparency and accountability across the organization.",
          },
        ],
      },
    ],
  };

  return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-full max-[900px]:w-full flex flex-col  gap-5 text-white text-md p-8 text-md">
        <div className="w-full flex items-center justify-center">
          <img
            src={img}
            alt="img"
            loading="lazy"
            className="w-4/5 max-[400px]:w-full h-[380px] rounded-xl"
          />
        </div>
        <div className="w-full flex text-lg items-center  gap-2">
          {prod.head_name} : {prod.title}
        </div>
        <h1 className="text-white text-2xl font-medium">Introduction</h1>
        <p>{prod.intro_description}</p>
        <h1 className="text-white text-2xl font-medium">{prod.ques}</h1>
        <p>{prod.ques_description}</p>
        {prod.topics.map((data) => (
          <div key={data.id} className="w-full flex flex-col gap-4">
            <h1 className="text-white text-2xl font-medium">{data.title}</h1>

            {data.benefits.map((point, index) => (
              <li key={index}>
                <span className="font-medium text-white"> {point.title}</span>{" "}
                {point.content}
              </li>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default MapleDesk;
