import React, { useEffect, useState } from "react";
import colimg from "../../images/aboutuspage/colag-img.png";
import ceo from "../../images/aboutuspage/ceo.png";
import hr_head from "../../images/aboutuspage/hr-head.png";
import team from "../../images/aboutuspage/team.png";
import team_ from "../../images/aboutuspage/team_.png";
import team4 from "../../images/aboutuspage/team4.png";
import team5 from "../../images/aboutuspage/team5.png";
import team6 from "../../images/aboutuspage/team6.png";
import team2 from "../../images/aboutuspage/team2.png";
import team7 from "../../images/aboutuspage/team7.png";
import AOS from "aos";
import "aos/dist/aos.css";

function AboutUs() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [more, setMore] = useState(false);

  return (
    <div className=" oveflow-hidden home-about w-full  gap-10 justify-center py-10 max-[650px]:px-6 px-20">
      <div
        data-aos="fade-up"
        className="w-full text-white flex flex-col gap-3 "
      >
        <p className="text-cyan-400 text-2xl font-medium">ABOUT US</p>
        <p className="text-md max-[800px]:text-xs max-[500px]:text-justify">
          Welcome to MapleCloud Technologies, a distinguished{" "}
          <span className="text-cyan-400 text-lg">"Make in India" </span>{" "}
          Original Equipment Manufacturer (OEM) at the forefront of
          cybersecurity solutions. Our commitment to Indigenous innovation
          drives us to deliver comprehensive digital defense offerings to secure
          and protect your digital landscape. MapleCloud Technologies takes
          pride in being a "Make in India" cybersecurity powerhouse, delivering
          world-class solutions developed on Indian soil. We stand as a trusted
          partner in digital security. Join us on a journey of resilience and
          innovation, where each product reflects our dedication to
          technological advancement and the protection of your digital assets.
          Experience the power of indigenous cybersecurity solutions tailored
          for the modern digital landscape.
        </p>
        <p className="text-cyan-400 text-2xl font-medium my-14">
          WHY CHOOSE US
        </p>
      </div>
      <div className="max-[500px]:px-0 max-[500px]:grid-cols-1 grid grid-cols-3 gap-6 w-full max-[650px]:px-6 max-[780px]:mt-5">
        <div
          data-aos="zoom-in"
          className=" bg-neutral-700 bg-opacity-35 flex flex-col gap-3 py-6 max-[650px]:py-3  px-8 max-[950px]:px-6 max-[650px]:px-4 rounded-xl  text-white 5 w-full"
        >
          <span className="font-bold text-xl max-[650px]:text-sm">01</span>
          <span className="text-xl   max-[780px]:text-sm max-[650px]:text-[14px] font-medium">
            Threat Intelligence
          </span>
          <p className="text-sm text-gray-300 max-[780px]:text-xs ">
            Threat information that has been aggregated, transformed, analyzed,
            interpreted, or enriched to provide the necessary context for
            decision-making processes.
          </p>
        </div>
        <div
          data-aos="zoom-in"
          className=" bg-neutral-700 bg-opacity-35 flex flex-col gap-3 py-6 max-[650px]:py-3  px-8 max-[950px]:px-6 max-[650px]:px-4 rounded-xl  text-white 5 w-full"
        >
          <span className="font-bold text-xl max-[650px]:text-sm">02</span>
          <span className="text-xl   max-[780px]:text-sm max-[650px]:text-[14px] font-medium">
            Malware Removal
          </span>
          <p className="text-sm text-gray-300 max-[780px]:text-xs ">
            There are thousands of websites hacked every day. The hackers'
            targets are to damage your website, breach your data, redirect
            traffic, and install a virus on your site.
          </p>
        </div>
        <div
          data-aos="zoom-in"
          className=" bg-neutral-700  bg-opacity-35 flex flex-col gap-3 py-6 max-[650px]:py-3  px-8 max-[950px]:px-6 max-[650px]:px-4 rounded-xl  text-white 5 w-full"
        >
          <span className="font-bold text-xl max-[650px]:text-sm">03</span>
          <span className="text-xl  max-[780px]:text-sm max-[650px]:text-[14px] font-medium">
            24/7 Technical Support
          </span>
          <p className="text-sm text-gray-300 max-[780px]:text-xs ">
            At MapleCloud , we recognize the vital role of cybersecurity in
            today's digital world. Our 24/7 technical support team is committed
            to providing swift assistance, ensuring the protection of your
            digital assets.
          </p>
        </div>
      </div>
      <div className="w-full grid grid-cols-2 max-[500px]:grid-cols-1 gap-10 mt-14 justify-center text-white  px-8 pt-14 bg-neutral-600 bg-opacity-55 border rounded-lg border-cyan-500 ">
        <div className="w-full h-full">
          <p className="text-cyan-400 text-2xl font-medium">WHO ARE WE</p>
          <p className="text-lg mt-4 max-[800px]:text-xs pb-3">
            Our mission is to empower businesses with robust security measures,
            ensuring trusted relationships between organizations and their
            customers, partners, and employees. With our specialized
            cybersecurity services, compliance support, IT enablement, and
            security training, we help companies stay secure and resilient in
            the digital landscape.
            <br />
            <br />
            MapleCloud Technologies is dedicated to providing the highest levels
            of security, compliance, and operational efficiency for your
            business. Join us in building a safer digital future.
          </p>
        </div>
        <img src={colimg} alt="img" className="w-full h-[90%] rounded-lg" />
      </div>
      <div className="w-full  mt-14 justify-center text-white  f rounded-lg  gap-3 ">
        <div className="w-full h-full">
          <p className="text-cyan-400 text-xl font-medium">MEET THE TEAM</p>
          <div className="grid grid-cols-3 max-[500px]:grid-cols-2 gap-10 max-[950px]:gap-4 mt-10 px-14 max-[950px]:px-0">
            <div className="w-[80%] bg-cyan-600 max-[950px]:w-[100%] h-[300px] rounded-lg border border-cyan-400">
              <img
                src={ceo}
                alt="img"
                className="w-full rounded-lg h-[70%] object-fill"
              />
              <div className="h-[30%] max-[950px]:w-[100%] w-full  text-sm max-[900px]:text-sm font-medium text-white flex flex-col gap-2 justify-center p-4">
                <p>YOGENDRA RAJPUT</p>
                <p>MANAGING DIRECTOR</p>
              </div>
            </div>
            <div className="w-[80%] bg-cyan-600 max-[950px]:w-[100%] h-[300px] rounded-lg border border-cyan-400">
              <img
                src={hr_head}
                alt="img"
                className="w-full rounded-lg h-[70%] object-fill"
              />
              <div className="h-[30%] max-[950px]:w-[100%] w-full text-sm max-[900px]:text-sm font-medium text-white flex flex-col gap-2 justify-center p-4">
                <p>NEHA JAIN</p>
                <p>DIRECTOR</p>
              </div>
            </div>
            <div className="w-[80%] bg-cyan-600 max-[950px]:w-[100%] h-[300px] rounded-lg border border-cyan-400">
              <img
                src={team}
                alt="img"
                className="w-full rounded-lg h-[70%] object-fill"
              />
              <div className="h-[30%] max-[950px]:w-[100%] w-full text-sm max-[900px]:text-sm font-medium text-white flex flex-col gap-2 justify-center p-4">
                <p>TAVISH THAKUR</p>
                <p>BUSINESS HEAD</p>
              </div>
            </div>
            <div className="w-[80%] bg-cyan-600 max-[950px]:w-[100%] h-[300px] rounded-lg border border-cyan-400">
              <img
                src={team_}
                alt="img"
                className="w-full rounded-lg h-[70%] object-fill"
              />
              <div className="h-[30%] w-full text-sm max-[900px]:text-sm font-medium text-white flex flex-col gap-2 justify-center p-4">
                <p>AKHIL RAUTH</p>
                <p>CYBER SECURITY ANALYST</p>
              </div>
            </div>
            <div className="w-[80%] bg-cyan-600 max-[950px]:w-[100%] h-[300px] rounded-lg border border-cyan-400">
              <img
                src={team5}
                alt="img"
                className="w-full rounded-lg h-[70%] object-fill"
              />
              <div className="h-[30%] w-full text-sm max-[900px]:text-sm font-medium text-white flex flex-col gap-2 justify-center p-4">
                <p>MD AAMIR SOHAIL KHAN</p>
                <p>CYBER SECURITY ENGINEER</p>
              </div>
            </div>
            <div className="w-[80%] bg-cyan-600 max-[950px]:w-[100%] h-[300px] rounded-lg border border-cyan-400">
              <img
                src={team4}
                alt="img"
                className="w-full rounded-lg h-[70%] object-fill"
              />
              <div className="h-[30%] max-[950px]:w-[100%] w-full text-sm max-[900px]:text-sm font-medium text-white flex flex-col gap-2 justify-center p-4">
                <p>BHARAT JADON</p>
                <p>DEVOPS ENGINEER</p>
              </div>
            </div>
          </div>
          {more ? (
            ""
          ) : (
            <p
              onClick={() => setMore(true)}
              className="text-lg text-cyan-300 font-medium cursor-pointer ml-16 mt-10 "
            >
              See more...
            </p>
          )}

          {more ? (
            <div className="grid grid-cols-3 max-[500px]:grid-cols-2 gap-10 max-[950px]:gap-4 mt-10 px-14 max-[950px]:px-0">
              <div className="w-[80%] bg-cyan-600 max-[950px]:w-[100%] h-[300px] rounded-lg border border-cyan-400">
                <img
                  src={team7}
                  alt="img"
                  className="w-full rounded-lg h-[70%] object-fill"
                />
                <div className="h-[30%] w-full text-sm max-[900px]:text-sm font-medium text-white flex flex-col gap-2 justify-center p-4">
                  <p>MRANAL</p>
                  <p>AVP SALES</p>
                </div>
              </div>
              <div className="w-[80%] bg-cyan-600 max-[950px]:w-[100%] h-[300px] rounded-lg border border-cyan-400">
                <img
                  src={team2}
                  alt="img"
                  className="w-full rounded-lg h-[70%] object-fill"
                />
                <div className="h-[30%] max-[950px]:w-[100%] w-full text-sm max-[900px]:text-sm font-medium text-white flex flex-col gap-2 justify-center p-4">
                  <p>MUKESH TYAGI</p>
                  <p>PROJECT MANAGER</p>
                </div>
              </div>
              <div className="w-[80%] bg-cyan-600 max-[950px]:w-[100%] h-[300px] rounded-lg border border-cyan-400">
                <img
                  src={team6}
                  alt="img"
                  className="w-full rounded-lg h-[70%] object-fill"
                />
                <div className="h-[30%] w-full text-sm max-[900px]:text-sm font-medium text-white flex flex-col gap-2 justify-center p-4">
                  <p>KARTIKEYA AWASTHI</p>
                  <p>SENIOR BUSINESS ANALYST</p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
