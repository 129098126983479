import React from "react";
import { CiLinkedin } from "react-icons/ci";
import { PiYoutubeLogoLight } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import { FaChevronRight } from "react-icons/fa6";
import data from "../../src/pages/data/Product.json";
// import data from "../../pages/data/Product.json";
import nms from "../images/products/mct-nms.jpg";
import doc from "../images/products/maple-doc.avif";
import siem from "../images/products/SIEM.png";
import email_gateway from "../images/products/email-gatway.jpg";
import rim from "../images/products/rim.avif";
import agent from '../images/products/agent-net.png'
import ncms from "../images/products/ncms.jpg";
import eventsheild from "../images/products/blog-two-2.jpg";
import mapledesk from "../images/products/blog-two-6.jpg";
import mapleLog from "../images/products/blog-two-5.jpg"
import img8 from "../images/products/switch.jpg";
import firewall from "../images/products/firewall.jpg";
function Footer() {
  const navigate = useNavigate();
     const products = [
    {
      name: "Eventsheild",
      image: eventsheild,
      route: "eventshield",
    },
    {
      name: "Maple Doc",
      image: doc,
      route: "maple_doc",
    },
    {
      name: "Maple Desk",
      image: mapledesk,
      route: "maple_desk",
    },
    {
      name: "MCT NMS",
      image: nms,
      route: "maple_nms",
    },
    {
      name: "DevRay Switches",
      image: img8,
      route: "devray_switches",
    },
    {
      name: "Next Gen-FIREWALL",
      image: firewall,
      route: "next_gen_firewall",
    },
    {
      name: "MCT NCMS",
      image: ncms,
      route: "maple_ncms",
    },
    {
      name: "MCT Authenticator",
      image: siem,
      route: "mct_auth",
    },
    {
      name: "Maple Log",
      image: mapleLog,
      route: "maple_log",
    },
    {
      name: "MailBridges",
      image: email_gateway,
      route: "MailBridge",
    },
    {
      name: "MCT Access Watch",
      image: rim,
      route: "nac",
    },
    {
      name: "MCT AgentNet",
      image: agent,
      route: "Sdwan",
    },
  ];

  
  return (
    <div className="footer  w-full gap-4 flex flex-col max-[1250px]:px-5 items-center py-10 px-20 justify-center">
      <div className="w-full gap-10 flex max-[550px]:flex-col max text-white">
        <div className="flex flex-col w-full  text-white ">
          <img src={logo} alt="logo img" className="w-[180px]" />
          <div className="flex mt-5 gap-5 text-3xl font-medium text-white items-center">
            <a
              href="https://www.youtube.com/@MapleCloudTechnologies"
              target="blank"
            >
              <PiYoutubeLogoLight />
            </a>
            <a
              href="https://in.linkedin.com/company/maplecloudtechnologies?trk=similar-pages"
              target="blank"
            >
              <CiLinkedin />
            </a>
          </div>
        </div>
        <div className="flex text-white w-full flex-col  gap-3 ">
          <h1 className="font-medium w-full text-lg ">Service Links</h1>
          <div className="flex flex-col gap-2  text-sm ">
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/cloud"> Cloud</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/rim">MCTRIM</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/data_center">Data Center</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/network">Enterprise Network</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/manageservice">Managed Services</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/operation">Network Field Operation</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/ict">ICT Security</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/mtech">Multi-vendor Technical Support</Link>
            </div>
            <div className="flex items-center gap-2">
              <FaChevronRight className="text-xs text-cyan-400" />
              <Link to="/nrc">Network Rollout Commissioning</Link>
            </div>
          </div>
        </div>
        <div className="flex text-white w-full flex-col  gap-3 ">
          <h1 className="font-medium w-full text-lg ">Product Links</h1>
          <div className="flex flex-col gap-2  text-sm ">
            {
              products.map((prod) => (
                <div className="flex items-center gap-2 cursor-pointer">
                <FaChevronRight className="text-xs text-cyan-400" />
                <div   onClick={() =>
                            navigate(`${prod.route}`, {
                              state: {
                                img: prod.image
                              },
                            })
                          }>{prod.name}</div>
              </div>
              ))
            }
           
          </div>
        </div>
       
       
      </div>
    </div>
  );
}
export default Footer;
