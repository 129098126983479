import React, { useEffect, useState } from "react";
import ChatWindow from "./ChatWindow";
import { motion } from "framer-motion";
import { FaComments, FaTimes } from "react-icons/fa";
import "./ChatbotContainer.css";

const ChatbotContainer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsOpen(true);
  //   }, 5000);

  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <div className="chatbot-container">
      <motion.div
        className={`toggle-icon ${isOpen ? "open" : ""}`}
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.9 }}
        onClick={toggleChat}
      >
        {isOpen ? <FaTimes /> : <FaComments />}
      </motion.div>

      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
        >
          <ChatWindow closeChat={toggleChat} />
        </motion.div>
      )}
    </div>
  );
};

export default ChatbotContainer;
