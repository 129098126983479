import React from "react";
import img8 from "../../images/products/switch.jpg";
import firewall from "../../images/products/firewall.jpg";
import banner from "../../images/products/products.png";
import ncms from "../../images/products/ncms.jpg";
import eventsheild from "../../images/products/blog-two-2.jpg";
import mapledesk from "../../images/products/it.jpg";
import mapleai from "../../images/products/ai2.jpg";
import mapleLog from "../../images/products/blog-two-5.jpg";
import { useNavigate } from "react-router-dom";
import data from "../../pages/data/Product.json";
import nms from "../../images/products/mct-nms.jpg";
import doc from "../../images/products/maple-doc.avif";
import siem from "../../images/products/SIEM.png";
import email_gateway from "../../images/products/email-gatway.jpg";
import rim from "../../images/products/rim.avif";
import agent from "../../images/products/agent-net.png";
import filter_img_1 from "../../images/blog/ai_based.png";
import filter_img_2 from "../../images/blog/cyber.png";
import filter_img_3 from "../../images/blog/bus_dri_soft.png";
import filter_img_4 from "../../images/blog/all_prod.png";

function CyberSec() {
  const navigate = useNavigate();
  const prod_data = data; // Product data imported from JSON
  const products = [
    {
      name: "Event Shield - Security Information and Event Management",
      image: eventsheild,
      route: "eventshield", 
    },
    // {
    //   name: "Risk Assessment - Vulnerability Assessment",
    //   image: doc,
    //   route: "",
    // },
    {
      name: "Maple Eye - Threat Intelligence",
      image: mapleai,
      route: "maple_eye",
    },
    {
      name: "Maple Logs - Syslogs",
      image: mapleLog,
      route: "maple_log", 
    },
];


  return (
    <div className=" w-full">
      <div className="w-full grid grid-cols-3 max-[650px]:grid-cols-1 max-[1200px]:grid-cols-2 py-10 px-10 gap-6">
        {products.map((product, index) => (
          <div className="w-full p-5 flex flex-col gap-3 bg-stone-400 bg-opacity-10 rounded-xl">
            <div className="w-full bg-stone-300 bg-opacity-10 rounded-xl cursor-pointer">
              <img
                src={product.image}
                alt={product.name}
                className="rounded-t-xl h-[220px] w-full "
              />
              <p className="text-white p-4">
                <span className="text-xl font-medium">{product.name}</span>
              </p>
            </div>
            {/* <p className="text-sm text-gray-200">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere
              quia, suscipit aspernatur velit inventore voluptatem.
            </p> */}
            <p
              onClick={() =>
                navigate(`/${product.route}`, {
                  state: {
                    img: product.image,
                    name: product.name,
                  },
                })
              }
              className="text-blue-400 text-end cursor-pointer"
            >
              Know More
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CyberSec;
