import React from "react";
import { useLocation } from "react-router-dom";

const MapleEye = () => {
  const location = useLocation();
  const { img } = location.state || {};

  return (
    <div className="blog w-full ">
      <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
        <div className="w-full max-[900px]:w-full flex flex-col  gap-5 text-white text-md p-8 text-md">
          <div className="w-full flex items-center justify-center">
            <img
              src={img}
              alt="img"
              loading="lazy"
              className="w-4/5 max-[400px]:w-full h-[380px] rounded-xl"
            />
          </div>
          <div className="w-full flex text-lg items-center  gap-2">
            Maple Eye - Threat Intelligence Solution
          </div>
          <h1 className="text-white text-2xl font-medium">
            Unlock Advanced Threat Detection with Maple Eye
          </h1>
          <p>
            Maple Eye is a powerful and comprehensive solution designed to
            provide unmatched threat intelligence. Tailored to meet the evolving
            challenges of cybersecurity, Maple Eye equips organizations with the
            tools to safeguard their assets and maintain operational integrity.
            Divided into three specialized modules, Maple Eye delivers
            cutting-edge technology to address a wide range of security concerns
          </p>

          <h1 className="text-white text-2xl font-medium">
            Key Modules of Maple Eye
          </h1>
          <h1 className="text-white text-2xl font-medium">
            1. FRS - Face Recognition System
          </h1>
          <p>
            Maple Eye's FRS module offers a robust face recognition system that
            seamlessly integrates with IP cameras or other compatible devices.
            Key features include :{" "}
          </p>
          <li>
            Entity Identification : Recognizes individuals from a pre-stored
            database to streamline operations and enhance security
          </li>
          <li>
            In and Out Counting: Tracks entry and exit counts, providing
            real-time occupancy insights for enhanced monitoring
          </li>
          <li>
            Object Detection: Identifies objects within a scene and triggers
            appropriate alarms for potential threats.
          </li>
          <li>
            Alarm System: Delivers real-time alerts for unauthorized access or
            anomalies detected within the surveillance environment.
          </li>
          <h2 className="text-white text-2xl font-medium">
            2. Deep Fake Detection
          </h2>
          <p>
            Stay ahead in the digital age with Maple Eye’s advanced Deep Fake
            detection capabilities. This module empowers organizations to:
          </p>
          <li>
            Media Verification: Analyze uploaded media files to determine their
            authenticity and detect any deep-faked content.
          </li>
          <li>
            Enhanced Credibility: Protect organizational reputation by ensuring
            that only genuine content is used or disseminated.
          </li>
          <li>
            Proactive Protection: Identify manipulated media that could be used
            for malicious purposes, safeguarding against misinformation.
          </li>
          <h2 className="text-white text-2xl font-medium">
            3. Brand Protection
          </h2>
          <p>
            Maintain the integrity of your brand with Maple Eye’s Brand
            Protection module. This cutting- edge feature set includes
          </p>
          <li>
            Social Media Monitoring: Keep a vigilant eye on your brand’s
            presence across social media platforms to prevent impersonation or
            negative campaigns.
          </li>
          <li>
            URL Scanning: Identify malicious or fraudulent URLs associated with
            your brand and take immediate action to neutralize threats.
          </li>
          <li>
            Anti-Logo Phishing: Detect unauthorized use of your brand’s logo in
            phishing campaigns and safeguard your customers against fraud.
          </li>
          <h2 className="text-white text-2xl font-medium">
            Why Choose Maple Eye?
          </h2>
          <li>
            Comprehensive Security: A one-stop solution addressing physical,
            digital, and brand-related threats.
          </li>
          <li>
            Scalable Integration: Easily integrates with existing systems and
            infrastructure for seamless deployment
          </li>
          <li>
            Proactive Insights: Delivers real-time intelligence to empower quick
            and informed decision-making.
          </li>
          <li>
            Enhanced ROI: Helps protect valuable assets and reduce the risk of
            financial or reputational losses.
          </li>
          <h2 className="text-white text-2xl font-medium">
            Applications Across Industries
          </h2>
          <li>
            Retail: Monitor foot traffic and prevent in-store theft with FRS
          </li>
          <li>
            Finance: Detect deep-faked identities and protect customer accounts.
          </li>
          <li>
            E-commerce: Safeguard your brand from phishing scams and fraudulent
            URLs
          </li>
          <li>
            Public Safety: Enhance surveillance and ensure community security.
          </li>
          <h2 className="text-white text-2xl font-medium">Experience the Power of Maple Eye</h2>
          <p>Defend your organization against emerging threats with Maple Eye. Whether you’re 
safeguarding physical premises, verifying digital media, or protecting your brand’s 
reputation, Maple Eye’s modular design ensures a tailored solution to meet your unique 
needs.</p>
<h2 className="text-white text-xl font-medium">Contact us today to learn more about Maple Eye and how it can revolutionize your 
approach to threat intelligence.</h2>
        </div>
      </div>
    </div>
  );
};

export default MapleEye;
