import React, { useEffect, useState } from "react";
import { TbPhoneCall } from "react-icons/tb";
import { FiMail } from "react-icons/fi";
import { FaLocationDot } from "react-icons/fa6";

function Contact() {
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [quiery, setQuiery] = useState("");
  const [products, setProducts] = useState([]);

  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [captchaCode, setCaptchaCode] = useState("");

  const generateCaptcha = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setCaptchaCode(captcha);
    setCaptchaAnswer(""); // Clear the user's input
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (captchaAnswer !== captchaCode) {
      alert("Captcha is incorrect. Please try again.");
      generateCaptcha();
      return;
    }

    const templateParams = {
      first_name,
      last_name,
      email,
      phone,
      company_name,
      message: quiery,
      products: products.join(", "),
    };

    // emailjs
    //   .send(
    //     "service_4ftev35", // Replace with your Service ID
    //     "template_xq1j9bd", // Replace with your Template ID
    //     templateParams, // Pass the form data
    //     "RDhZeySr9d8GnDP3h" // Replace with your Public Key
    //   )
    //   .then(
    //     (response) => {
    //       console.log("Email sent successfully:", response.text);
    //       alert("Your query has been sent successfully!");
    //       setFirst_name("");
    //       setLast_name("");
    //       setEmail("");
    //       setPhone("");
    //       setCompany_name("");
    //       setQuiery("");
    //       setProducts([]);
    //     },
    //     (error) => {
    //       console.error("Failed to send email:", error.text);
    //     }
    //   );
    generateCaptcha();
  };

  return (
    <div className="blog w-full flex items-center justify-center">
      <div className="w-4/5 max-[800px]:w-full flex max-[800px]:flex-wrap">
        <div className="w-full grid grid-cols-1 gap-4 pt-32 pb-5 px-4">
          {/* India Address */}
          <div className="w-full rounded-xl bg-blue-800 bg-opacity-15 shadow-lg">
            <div className="w-full p-5 flex flex-col gap-2">
              <p className="font-medium text-cyan-400 text-xl">
                MapleCloud Technologies (India)
              </p>
              <div className="flex items-start gap-3">
                <FaLocationDot className="text-red-700 text-2xl" />
                <p className="text-white text-sm font-medium">
                  E-29, Third Floor, E Block, Sector 63, Noida, UP, India 201301
                </p>
              </div>
              <div className="flex items-start gap-3">
                <TbPhoneCall className="text-red-700 text-2xl" />
                <p className="text-white text-sm font-medium">
                  <a href="tel:+918178803636">+91-8178803636</a>
                </p>
              </div>
              <div className="flex items-center gap-3">
                <FiMail className="text-red-700 text-2xl" />
                <p className="text-white text-sm font-medium">
                  <a href="mailto:fly@maplecloudtechnologies.com">
                    fly@maplecloudtechnologies.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          {/* USA Address */}
          <div className="w-full rounded-xl bg-blue-800 bg-opacity-15 shadow-lg">
            <div className="w-full p-5 flex flex-col gap-2">
              <p className="font-medium text-cyan-400 text-xl">
                MapleCloud Technologies LLC (USA)
              </p>
              <div className="flex items-start gap-3">
                <FaLocationDot className="text-red-700 text-2xl" />
                <p className="text-white text-sm font-medium">
                  6100 Lake Ellenor Dr Suite 151 #1013 Orlando, FL 32809, USA
                </p>
              </div>
              <div className="flex items-start gap-3">
                <TbPhoneCall className="text-red-700 text-2xl" />
                <p className="text-white text-sm font-medium">
                  <a href="tel:+18778156055">+18778156055</a>
                </p>
              </div>
              <div className="flex items-center gap-3">
                <FiMail className="text-red-700 text-2xl" />
                <p className="text-white text-sm font-medium">
                  <a href="mailto:fly@maplecloudtechnologies.com">
                    fly@maplecloudtechnologies.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full rounded-xl bg-blue-800 bg-opacity-15 shadow-lg">
            {/* <img
          src={img3}
          alt="Canada Address"
          className="rounded-t-xl w-full h-56 object-cover"
        /> */}
            <div className="w-full p-5 flex flex-col gap-2">
              <p className="font-medium text-cyan-400 text-xl">
                {" "}
                MapleCloud Technologies (8537143 Canada Inc)
              </p>
              <div className="flex items-start gap-3">
                <FaLocationDot className="text-red-700 text-2xl" />
                <p className="text-white text-sm font-medium">
                  192 Sugarhill Drive, Brampton, Ontario, L7A3X6, Canada
                </p>
              </div>
              <div className="flex items-start gap-3">
                <TbPhoneCall className="text-red-700 text-2xl" />
                <p className="text-white text-sm font-medium">
                  <a href="tel:+18778156055">+18778156055</a>
                </p>
              </div>
              <div className="flex items-center gap-3">
                <FiMail className="text-red-700 text-2xl" />
                <p className="text-white text-sm font-medium">
                  <a href="mailto:fly@maplecloudtechnologies.com">
                    fly@maplecloudtechnologies.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <form
          onSubmit={handleSubmit}
          className="w-full pt-32 pb-5 px-4 max-[700px]:pt-4"
        >
          <h1 className="text-cyan-400 text-start font-semibold text-3xl">
            Get In Touch
          </h1>
          <div className="w-full grid grid-cols-2 mt-7 gap-4">
            <div className="flex w-full flex-col items-start gap-2 text-white text-lg">
              <label htmlFor="first_name">First Name*</label>
              <input
                type="text"
                required
                value={first_name}
                onChange={(e) => setFirst_name(e.target.value)}
                name="first_name"
                placeholder="First name"
                className="w-full px-3 py-2 bg-gray-400 bg-opacity-15 rounded-md text-sm outline-none"
              />
            </div>
            <div className="flex w-full flex-col items-start gap-2 text-white text-lg">
              <label htmlFor="last_name">Last Name*</label>
              <input
                type="text"
                required
                value={last_name}
                onChange={(e) => setLast_name(e.target.value)}
                name="last_name"
                placeholder="Last name"
                className="w-full px-3 py-2 bg-gray-400 bg-opacity-15 rounded-md text-sm outline-none"
              />
            </div>

            <div className="flex w-full flex-col items-start gap-2 text-white text-lg">
              <label htmlFor="email">Email*</label>
              <input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                placeholder="Email"
                className="w-full px-3 py-2 bg-gray-400 bg-opacity-15 rounded-md text-sm outline-none"
              />
            </div>
            <div className="flex w-full flex-col items-start gap-2 text-white text-lg">
              <label htmlFor="tel">Phone No*</label>
              <input
                type="tel"
                required
                value={phone}
                onChange={(e) => {
                  const phoneRegex = /^[0-9]*$/;
                  if (phoneRegex.test(e.target.value)) {
                    setPhone(e.target.value);
                  }
                }}
                name="tel"
                placeholder="Phone"
                className="w-full px-3 py-2 bg-gray-400 bg-opacity-15 rounded-md text-sm outline-none"
              />
            </div>
          </div>
          <div className=" flex w-full flex-col items-start gap-2 text-white text-lg mt-5">
            <label htmlFor="company_name">Compnay Name</label>
            <input
              type="text"
              value={company_name}
              onChange={(e) => setCompany_name(e.target.value)}
              name="company_name"
              placeholder="Company name"
              className="w-full px-3 py-2 bg-gray-400 bg-opacity-15 rounded-md text-sm outline-none"
            />
          </div>

          <div className=" flex w-full flex-col items-start gap-2 text-white text-lg mt-5">
            <label htmlFor="query">How can we help you</label>
            <span className="text-gray-500 text-sm"># Trending Topics</span>
            <div className="w-full my-2 flex flex-wrap gap-2 ">
              {[
                "#Cyber security",
                "#Firewall NextGen",
                "#SDWAN",
                "#HRMS",
                "#LMS",
                "#DMS",
                "#FRS",
                "#Vulneribilities",
                "#MFA",
                "#Network monitoring",
                "#SIEM",
              ].map((prod, idx) => (
                <div
                  onClick={() => setProducts([...products, prod])}
                  key={idx}
                  className={`${
                    products.includes(prod)
                      ? "bg-cyan-700 bg-opacity-32"
                      : "bg-gray-400 bg-opacity-15"
                  } cursor-pointer  text-xs text-white px-3 py-2 rounded-full`}
                >
                  {prod}
                </div>
              ))}
            </div>
            <textarea
              type="text"
              name="query"
              rows={4}
              value={quiery}
              onChange={(e) => setQuiery(e.target.value)}
              placeholder="Write your query..."
              className="w-full px-3 py-2 bg-gray-400 bg-opacity-15 rounded-md text-sm outline-none resize-none"
            ></textarea>
          </div>
          {/* <div className="flex w-full flex-row items-start gap-2 text-white text-lg mt-5">
            <label className="block">Captcha: {captchaCode}</label>
            <input
              type="text"
              value={captchaAnswer}
              onChange={(e) => setCaptchaAnswer(e.target.value)}
              placeholder="Enter Captcha"
              className="px-3 py-2 bg-gray-400 bg-opacity-15 rounded-md text-sm outline-none"
              required
            />
          </div> */}
          <div className="flex flex-col w-full items-start mt-5">
            <label className="text-white text-lg font-medium mb-2">
              Captcha
            </label>
            <div className="flex items-center gap-4">
              <div className="px-4 py-2 bg-gray-700 text-white text-lg font-bold rounded-md shadow-md">
                {captchaCode}
              </div>
              <input
                type="text"
                value={captchaAnswer}
                onChange={(e) => setCaptchaAnswer(e.target.value)}
                placeholder="Enter Captcha"
                className="w-1/2 px-3 py-2 border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
            <p className="text-gray-400 text-sm mt-2">
              Please enter the code shown above.
            </p>
          </div>

          <button
            type="submit"
            className="bg-cyan-800 hover:bg-cyan-600 text-white text-md py-2 rounded-md px-6 mt-4"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
