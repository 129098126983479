import React from "react";
import { useLocation } from "react-router-dom";

const Sdwan = () => {
   const location = useLocation();
    const { img  } = location.state || {};
   
    const prod =  {
      "id": 7,
      "img": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/product_SDWN.png",
      "img2": "https://maple-blogs.s3.ap-south-1.amazonaws.com/products/agent-net.png",
      "head_name": "MCT AgentNet",
      "name": "MCT AgentNet(SD-WAN)",
      "title": "Advanced Networking for Optimized Connectivity",
      "intro": "Introduction",
      "intro_description": "MapleCloud AgentNet (SD-WAN) is an innovative networking technology crafted to enhance, streamline, and secure your organization’s connectivity. Designed to intelligently manage and route network traffic across multiple locations, MCT AgentNet improves network performance, increases resilience, and optimizes bandwidth utilization, making it ideal for modern organizations with distributed teams and remote branches. Leveraging software-defined WAN (SD-WAN) capabilities, MCT AgentNet provides a smart, flexible solution for simplifying network management while improving efficiency and reducing costs.",
      "ques": "Why MCT AgentNet?",
      "ques_description": "In a world where connectivity is paramount, traditional networking methods often struggle to keep up with evolving business needs. MCT AgentNet is specifically engineered to address these challenges by dynamically routing traffic, optimizing network paths, and providing comprehensive control over your network. MCT AgentNet allows your organization to leverage bandwidth from multiple sources, including broadband, MPLS, and LTE, delivering a reliable, high-performance network solution that supports your team’s productivity and business growth.",
      "topics": [
        {
          "title": "Core Benefits of AgentNet",
          "benefits": [
            {
              "title": "Improved Network Performance :",
              "content": "MCT AgentNet optimizes the flow of traffic across your network, reducing latency, boosting application performance, and providing seamless user experiences even in high-demand environments."
            },
            {
              "title": "Enhanced Security :",
              "content": "Built with advanced security measures, MCT AgentNet safeguards your data and network traffic with encryption, secure access, and compliance with industry standards."
            },
            {
              "title": "Cost Efficiency :",
              "content": " By enabling you to balance traffic between lower-cost broadband and traditional MPLS, MCT AgentNet helps lower operational costs without compromising performance."
            },
            {
              "title": "Simplified Network Management :",
              "content": "MCT AgentNet provides a centralized, intuitive interface for monitoring, configuring, and managing network connections, allowing IT teams to work efficiently and respond to network needs in real-time."
            },
            {
              "title": "Scalability for Growing Needs :",
              "content": "Whether your organization has one location or a hundred, MCT AgentNet scales easily to meet expanding requirements, making it an ideal solution for organizations of any size."
            }
          ]
        },
        {
          "title": "Standout Features of MCT AgentNet",
          "benefits": [
            {
              "title": "Intelligent Traffic Routing :",
              "content": "MCT AgentNet uses advanced algorithms to direct traffic across the most efficient paths, prioritizing critical applications and ensuring consistent, high-quality performance across all locations."
            },
            {
              "title": "Multi-Path Support :",
              "content": "Combine multiple connection types, including MPLS, LTE, and broadband, to create a reliable, high-performance network that can adapt to any situation and recover seamlessly from failures."
            },
            {
              "title": "Real-Time Analytics and Monitoring :",
              "content": "Gain insights into network performance with MCT AgentNet’s detailed reporting and analytics tools. Track bandwidth usage, monitor device health, and stay on top of network trends to ensure optimal performance."
            },
            {
              "title": "Enhanced Security and Encryption :",
              "content": "MCT AgentNet protects your data with encryption and secure tunneling, ensuring privacy and compliance across the network while minimizing risks associated with data breaches."
            },
            {
              "title": "Application-Aware Traffic Management :",
              "content": "Prioritize network traffic based on application needs, ensuring that mission-critical applications receive the bandwidth and performance they require, even during peak times."
            },
            {
              "title": "Centralized Control and Policy Management : ",
              "content": "Set, apply, and enforce network policies across all locations from a single, centralized platform, simplifying management and reducing the time needed for configuration and troubleshooting."
            },
            {
              "title": "Failover and High Availability :",
              "content": " Built-in failover mechanisms ensure that your network remains available even if one connection type goes down, maintaining business continuity and reliable connectivity."
            }
          ]
        }
      ]
    }
  return (
    <div className="blog w-full ">
    <div className="flex pt-20 max-[900px]:flex-wrap w-full justify-between">
      <div className="w-full max-[900px]:w-full flex flex-col  gap-5 text-white text-md p-8 text-md">
        <div className="w-full flex items-center justify-center">
          <img
            src={img}
            alt="img"
            loading="lazy"
            className="w-4/5 max-[400px]:w-full h-[380px] rounded-xl"
          />
        </div>
        <div className="w-full flex text-lg items-center  gap-2">
          {prod.head_name} : {prod.title}
        </div>
        <h1 className="text-white text-2xl font-medium">Introduction</h1>
        <p>{prod.intro_description}</p>
        <h1 className="text-white text-2xl font-medium">{prod.ques}</h1>
        <p>{prod.ques_description}</p>
        {prod.topics.map((data) => (
          <div key={data.id} className="w-full flex flex-col gap-4">
            <h1 className="text-white text-2xl font-medium">{data.title}</h1>

            {data.benefits.map((point, index) => (
              <li key={index}>
                <span className="font-medium text-white"> {point.title}</span>{" "}
                {point.content}
              </li>
            ))}
          </div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default Sdwan; 
